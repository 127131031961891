import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import {
  getProfessorList,
  professorListSelectors,
  selectProfessorListFetchState,
} from '../../../../store/professors/professorList';
import TableCard from '../../../../components/TableCard';
import getStatusText from '../../../../library/utils/getStatusText';
import useDebounce from '../../../../library/hooks/useDebounce';

import { Modal } from '../../../../components';

import styles from './styles.module.scss';

const SearchTeacherModal = ({ onTeacherChosen, isOpen, onCancelPress }) => {
  const dispatch = useDispatch();
  const lsProfessors = useSelector(professorListSelectors.selectAll);
  const fetchState = useSelector(selectProfessorListFetchState);

  const [search, setSearch] = useState('');

  useDebounce(
    () => isOpen && dispatch(getProfessorList({ title: search })),
    [search, isOpen],
    {
      debounceAtStart: false,
    },
  );

  const goToPage = useCallback(
    (pageIndex) =>
      dispatch(
        getProfessorList({
          title: search,
          pageIndex,
        }),
      ),
    [dispatch, search],
  );

  return (
    <Modal isOpen={isOpen}>
      <div className={styles.searhStudentsContainer}>
        <TableCard
          contentLoaderStyle={{
            minWidth: 500,
          }}
          isLoading={fetchState.isLoading}
          page={fetchState.page}
          totalPages={fetchState.totalPages}
          onPageClick={goToPage}
          onNextPageClick={goToPage}
          onPreviusPageClick={goToPage}
          title="Lista de Professores"
          headerComponent={
            <div className={styles.searchContainer}>
              <div>
                <input
                  value={search}
                  className={styles.search}
                  placeholder="Buscar Professor..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Button
                color="primary"
                style={{ height: 40, marginTop: 6, marginRight: 8 }}
                onClick={(e) => {
                  e.preventDefault();
                  onCancelPress && onCancelPress();
                }}
                size="sm"
              >
                Cancelar
              </Button>
            </div>
          }
        >
          <thead className="thead-light">
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Email</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {lsProfessors.map((teacher) => (
              <tr
                key={teacher._id}
                className={styles.tableLine}
                onClick={() => {
                  onTeacherChosen && onTeacherChosen(teacher);
                }}
              >
                <td>{teacher.name}</td>
                <td>{teacher.email}</td>
                <td>{getStatusText(teacher.status)}</td>
              </tr>
            ))}
          </tbody>
        </TableCard>
      </div>
    </Modal>
  );
};

export default SearchTeacherModal;
