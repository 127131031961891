/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

export default function CheckBox({
  text,
  value = false,
  onClick,
  style,
  spanStyle,
}) {
  return (
    <div
      role="button"
      onClick={onClick}
      className="custom-control custom-control-alternative custom-checkbox"
      style={style}
    >
      <input
        className="custom-control-input"
        id="customCheckLogin"
        type="checkbox"
        checked={value}
      />
      <div className="custom-control-label">
        <span style={{ color: '#9698b2', ...spanStyle }}>{text}</span>
      </div>
    </div>
  );
}
