import React from 'react';

import styles from './styles.module.scss';

const Modal = ({ children, isOpen }) => {
  if (!isOpen) {
    document.body.style.overflow = 'visible';

    return null;
  }

  return (
    <div className={styles.loading_overlay}>
      <section className="modal-main">{children}</section>
    </div>
  );
};

export default Modal;
