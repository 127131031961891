import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';

import useValidateField from '../../../../library/hooks/useValidateField';
import { validateAll } from '../../../../library/utils/validations';
import { showToast } from '../../../../store/toast/index';
import {
  Input,
  DisplayField,
  ContentLoader,
  RadioList,
  SimplePopUp,
} from '../../../../components';
import {
  selectStudentDetails,
  selectStudentDetailsFetchState,
  getStudentDetails,
  updateStudentById,
  resetStudent,
  createStudent,
} from '../../../../store/students/studentDetails';
import navigationKeys from '../../../../library/enums/navigationKeys';
import useFomInitialData from '../../../../library/hooks/useFomInitialData';
import { deleteStudent } from '../../../../store/students/studentDetails/thunkActions';

export default function StudentDetail({ match, history }) {
  const userId = useMemo(() => match.params.userId, [match.params]);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const fetchState = useSelector(selectStudentDetailsFetchState);
  const student = useSelector(selectStudentDetails);

  const [userStatus, setUserStatus] = useState(null);

  const [
    confirmStudentRemovePopUpOpen,
    setConfirmStudentRemovePopUpOpen,
  ] = useState(false);

  useEffect(() => {
    if (student) {
      setUserStatus(student.status);
    }
  }, [student]);

  const initialData = useFomInitialData(
    {
      name: '',
      city: '',
      complement: '',
      cpf: '',
      birthday: '',
      email: '',
      gender: '',
      surname: '',
      address: '',
      neighborhood: '',
      phone: '',
      state: '',
      enrollment: '',
    },
    student,
  );

  const validateField = useValidateField(formRef);

  useEffect(() => {
    if (userId) {
      dispatch(getStudentDetails(userId));
    }
    return () => {
      dispatch(resetStudent());
    };
  }, [userId, dispatch]);

  const handleSubmit = useCallback(
    (form) => {
      if (userStatus === null) {
        dispatch(
          showToast({
            type: 'danger',
            text: 'Status Obrigatório',
          }),
        );

        return;
      }

      const [formErrors, isValid] = validateAll(form);

      if (!isValid) {
        formRef.current.setErrors(formErrors);
        return;
      }

      if (!userId) {
        const newStudent = {
          name: form.name,
          phone: form.phone,
          surname: form.surname,
          password: form.newPassword,
          email: form.email,
          enrollment: form.enrollment,
          neighborhood: form.neighborhood,
          complement: form.complement,
          status: userStatus,
          address: form.address,
          city: form.city,
          gender: form.gender,
          cpf: form.cpf,
          state: form.state,
          birthday: form.birthday,
        };

        dispatch(createStudent(newStudent)).then((action) => {
          if (action.payload && action.payload.id) {
            history.push(
              `${navigationKeys.ADMIN_STUDENTS}/${action.payload.id}`,
            );
          }
        });

        return;
      }

      const studentUpdated = {
        id: student.id,
        name: form.name,
        phone: form.phone,
        surname: form.surname,
        email: form.email,
        enrollment: form.enrollment,
        neighborhood: form.neighborhood,
        complement: form.complement,
        status: userStatus,
        address: form.address,
        city: form.city,
        gender: form.gender,
        cpf: form.cpf,
        state: form.state,
        birthday: form.birthday,
      };

      dispatch(updateStudentById(studentUpdated));
    },
    [dispatch, student, formRef, userId, history, userStatus],
  );

  if (fetchState.isLoading) {
    return <ContentLoader isLoading />;
  }

  return (
    <Container>
      <Col style={{ margin: '20px 0' }}>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {userId ? 'Informações de aluno' : 'Criação de Aluno'}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={handleSubmit}
              ref={formRef}
              initialData={initialData}
            >
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Nome"
                      name="name"
                      className="form-control-alternative"
                      placeholder="Digite o nome..."
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Sobrenome"
                      name="surname"
                      className="form-control-alternative"
                      placeholder="Digite o sobrenome..."
                    />
                  </Col>
                  {student && student.enrollment && (
                    <Col lg="6">
                      <DisplayField
                        label="Matrícula"
                        value={student.enrollment}
                      />
                    </Col>
                  )}
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Email"
                      name="email"
                      className="form-control-alternative"
                      placeholder="Digite o email"
                      type="email"
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Telefone"
                      name="phone"
                      className="form-control-alternative"
                      placeholder="Digite o nome..."
                      type="phone"
                      mask="(99) 99999-9999"
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Endereço"
                      name="address"
                      className="form-control-alternative"
                      id="input-first-name"
                      placeholder="Digite Seu Primeiro Nome..."
                      type="text"
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Complemento"
                      name="complement"
                      className="form-control-alternative"
                      placeholder="Digite o complemento..."
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Bairro"
                      name="neighborhood"
                      className="form-control-alternative"
                      placeholder="Digite o Bairro..."
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="UF"
                      name="state"
                      className="form-control-alternative"
                      id="input-last-name"
                      placeholder="Digite a uf..."
                      type="text"
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Cidade"
                      name="city"
                      className="form-control-alternative"
                      placeholder="Digite a cidade..."
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Data de Nascimento"
                      name="birthday"
                      className="form-control-alternative"
                      placeholder="Digite a data..."
                      mask="99/99/9999"
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Gênero"
                      name="gender"
                      className="form-control-alternative"
                      placeholder="Digite o genero..."
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="CPF"
                      name="cpf"
                      className="form-control-alternative"
                      placeholder="Digite seu Sobrenome"
                      mask="999.999.999.99"
                    />
                  </Col>
                  {!userId && (
                    <>
                      <Col lg="6">
                        <Input
                          validateField={validateField}
                          label="Senha"
                          name="newPassword"
                          className="form-control-alternative"
                          placeholder="Digite a senha"
                          type="password"
                          autoComplete="new-password"
                        />
                      </Col>
                      <Col lg="6">
                        <Input
                          validateField={validateField}
                          label="Confirmar Senha"
                          name="confirmNewPassword"
                          className="form-control-alternative"
                          placeholder="Confirme a senha"
                          type="password"
                          autoComplete="new-password"
                        />
                      </Col>
                    </>
                  )}
                  <Col lg="6">
                    <RadioList
                      onValueChange={(value) => setUserStatus(value)}
                      value={userStatus}
                      title="Status"
                      options={[
                        { label: 'Ativo', value: true },
                        { label: 'Inativo', value: false },
                      ]}
                    />
                  </Col>
                </Row>
              </div>
              <Col className="text-right" xs="12">
                <Row className="justify-content-end">
                  {student && (
                    <Button
                      color="secondary"
                      size="m"
                      onClick={() => setConfirmStudentRemovePopUpOpen(true)}
                    >
                      Deletar
                    </Button>
                  )}
                  <Button color="primary" size="m" type="submit">
                    Salvar
                  </Button>
                </Row>
              </Col>
            </Form>
          </CardBody>
        </Card>
      </Col>

      {student && (
        <SimplePopUp
          isOpen={confirmStudentRemovePopUpOpen}
          onCancelClick={() => setConfirmStudentRemovePopUpOpen(false)}
          onConfirmClick={() => {
            setConfirmStudentRemovePopUpOpen(false);

            dispatch(deleteStudent(student._id)).then((action) => {
              if (!action.error) {
                history.replace('/admin/students');
                return;
              }

              setConfirmStudentRemovePopUpOpen(true);
            });
          }}
        >
          <span>
            Tem certeza que deseja deletar o Aluno{' '}
            <span style={{ fontWeight: 'bold' }}>{student.name}</span>?
          </span>
        </SimplePopUp>
      )}
    </Container>
  );
}
