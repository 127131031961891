import React from 'react';
import { Badge } from 'reactstrap';
import getStatusText from '../../library/utils/getStatusText';

const getIconClass = (status) => {
  if (typeof status !== 'boolean') {
    return 'bg-warning';
  }

  if (status) {
    return 'bg-success';
  }

  return 'bg-info';
};

export default function DisplayStatus({ status = '' }) {
  return (
    <Badge color="" className="badge-dot mr-4">
      <i className={getIconClass(status)} />
      {getStatusText(status)}
    </Badge>
  );
}
