import { createSlice } from '@reduxjs/toolkit';

import {
  getStudentDetails,
  updateStudentById,
  createStudent,
} from './thunkActions';

const initialState = {
  data: null,
  fetchState: {
    isLoading: false,
    hasError: false,
  },
};

const studentDetails = createSlice({
  name: 'root/studentDetails',
  initialState,
  reducers: {
    resetStudent: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getStudentDetails.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getStudentDetails.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.data = action.payload;
    },
    [getStudentDetails.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },

    [updateStudentById.fulfilled]: (state, action) => {
      state.data = action.payload;
    },

    [createStudent.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export { getStudentDetails, updateStudentById, createStudent };

export const selectStudentDetails = (state) => state.studentDetails.data;

export const selectStudentDetailsFetchState = (state) =>
  state.studentDetails.fetchState;

export const { resetStudent } = studentDetails.actions;

export default studentDetails.reducer;
