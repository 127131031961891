import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import navigationKeys from '../../../../library/enums/navigationKeys';
import {
  getSubscriptionList,
  subscriptionListSelectors,
  selectSubscriptionListFetchState,
} from '../../../../store/subscriptions/subscriptionList';
import TableCard from '../../../../components/TableCard';

import styles from './madiators-table.module.scss';
import useDebounce from '../../../../library/hooks/useDebounce';

const SubscriptionsTable = ({ history }) => {
  const dispatch = useDispatch();
  const lsSubscriptions = useSelector(subscriptionListSelectors.selectAll);
  const fetchState = useSelector(selectSubscriptionListFetchState);

  const [search, setSearch] = useState('');

  useDebounce(() => dispatch(getSubscriptionList({ name: search })), [search], {
    debounceAtStart: false,
  });

  const goToPage = useCallback(
    (pageIndex) => dispatch(getSubscriptionList({ pageIndex, name: search })),
    [dispatch, search],
  );

  return (
    <>
      <div className={styles.backgroundTop} />
      <div className={styles.container}>
        <div className="col">
          <div className="row align-items-center">
            <div>
              <input
                value={search}
                className={styles.search}
                placeholder="Buscar Assinatura..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <TableCard
            isLoading={fetchState.isLoading}
            page={fetchState.page}
            totalPages={fetchState.totalPages}
            onPageClick={goToPage}
            onNextPageClick={goToPage}
            onPreviusPageClick={goToPage}
            title="Lista de Assinaturas"
            showHeaderButton
            headerButtonClick={() =>
              history.push(`${navigationKeys.ADMIN_SUBSCRIPTIONS}/create`)
            }
            headerButtonText="Criar Novo Assinatura"
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Nome do Usuário</th>
                <th scope="col">Plano</th>
                <th scope="col">Status de pagamento</th>
                <th scope="col">Vencimento</th>
                <th scope="col">Forma de pagamento</th>
              </tr>
            </thead>
            <tbody>
              {lsSubscriptions.map((user) => (
                <tr
                  key={user._id}
                  className={styles.tableLine}
                  onClick={() =>
                    history.push(
                      `${navigationKeys.ADMIN_SUBSCRIPTIONS}/${user._id}`,
                    )
                  }
                >
                  <td>Gerônimo Mock</td>
                  <td>Mockado</td>
                  <td>Bem Depois do Vencimento</td>
                  <td>10/10/10</td>
                  <td>qualquer</td>
                </tr>
              ))}
            </tbody>
          </TableCard>
        </div>
      </div>
    </>
  );
};

export default SubscriptionsTable;
