import { createSlice } from '@reduxjs/toolkit';

const configs = createSlice({
  name: 'root/configs',
  initialState: {
    tokenId: null,
  },
  reducers: {
    setTokenId: (state, action) => {
      state.tokenId = action.payload;
    },
    resetTokenId: (state) => {
      state.tokenId = null;
    },
  },
});

export const { setTokenId, resetTokenId } = configs.actions;
export const selectTokenId = (state) => state.configs.tokenId;

export default configs.reducer;
