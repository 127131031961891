import { createAsyncThunk } from '@reduxjs/toolkit';
import { showToast } from '../../toast';
import { showLoader, hideLoader } from '../../loader';
import CoursesService from '../../../services/CoursesService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';

export const getCourseDetails = createAsyncThunk(
  'courseDetails/getCourseDetails',
  async (courseId, { dispatch }) => {
    try {
      const response = await CoursesService.getCourseById(courseId);

      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const updateCourseById = createAsyncThunk(
  'courseDetails/updateCourseById',
  async ({ id, formData }, { dispatch }) => {
    try {
      const response = await CoursesService.updateCourseById(id, formData);

      dispatch(
        showToast({
          type: 'success',
          text: 'Curso Atualizado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createCourse = createAsyncThunk(
  'courseDetails/createCourse',
  async (course, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await CoursesService.createCourse(course);

      dispatch(
        showToast({
          type: 'success',
          text: 'Curso Criado com sucesso!',
        }),
      );

      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const deleteCourse = createAsyncThunk(
  'courseDetails/deleteCourse',
  async (courseId, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await CoursesService.deleteCourse(courseId);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Course deletado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
