import { createAsyncThunk } from '@reduxjs/toolkit';
import { showToast } from '../../toast';
import { showLoader, hideLoader } from '../../loader';
import ProfessorsService from '../../../services/ProfessorsService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';

export const getProfessorDetails = createAsyncThunk(
  'professorsDetails/getProfessorDetails',
  async (professorsId, { dispatch }) => {
    try {
      const response = await ProfessorsService.getProfessorById(professorsId);

      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const updateProfessorById = createAsyncThunk(
  'professorList/updateProfessorById',
  async ({ id, ...rest }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await ProfessorsService.updateProfessorById(id, rest);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Professor Atualizado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createProfessor = createAsyncThunk(
  'professorDetails/createProfessor',
  async (professor, { dispatch }) => {
    try {
      dispatch(showLoader());

      const response = await ProfessorsService.createProfessor(professor);

      dispatch(
        showToast({
          type: 'success',
          text: 'Professor Criado com sucesso!',
        }),
      );

      dispatch(hideLoader());

      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const deleteProfessor = createAsyncThunk(
  'professorDetails/deleteProfessor',
  async (professorId, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await ProfessorsService.deleteProfessor(professorId);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Professor deletado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
