import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { getSubscriptionList } from './thunkActions';

const subscriptionListAdapter = createEntityAdapter();

const initialState = subscriptionListAdapter.getInitialState({
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
});

const configs = createSlice({
  name: 'root/subscriptionList',
  initialState,
  extraReducers: {
    [getSubscriptionList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getSubscriptionList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      subscriptionListAdapter.setAll(state, action.payload.mediators);
    },
    [getSubscriptionList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
  },
});

export { getSubscriptionList };

export const subscriptionListSelectors = subscriptionListAdapter.getSelectors(
  (state) => state.subscriptionList,
);

export const selectSubscriptionListFetchState = (state) =>
  state.subscriptionList.fetchState;

export default configs.reducer;
