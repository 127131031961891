import React, {
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useDispatch } from 'react-redux';
import SimplePopUp from '../SimplePopUp';
import DebatesService from '../../services/DebatesService';
import CoursesService from '../../services/CoursesService';
import { showToast } from '../../store/toast';
import { showLoader, hideLoader } from '../../store/loader';
import { handleErrorMessage } from '../../library/utils/errorHandler';

const deleteModalInitialState = {
  open: false,
  tag: null,
};

// eslint-disable-next-line no-empty-pattern
const RemoveTagModal = ({ id, onDebateDeleted, type }, ref) => {
  const dispatch = useDispatch();
  const [deleteModalState, setDeleteModalState] = useState(
    deleteModalInitialState,
  );

  useImperativeHandle(ref, () => ({
    open: (tag) => {
      setDeleteModalState({
        open: true,
        tag,
      });
    },
  }));

  const resetDeleteModal = useCallback(
    () => setDeleteModalState(deleteModalInitialState),
    [],
  );

  const handleDelete = useCallback(async () => {
    setDeleteModalState({
      ...deleteModalState,
      open: false,
    });

    try {
      dispatch(showLoader());

      if (type === 'debate') {
        await DebatesService.removeTagFromDebate(id, deleteModalState.tag._id);
      } else {
        await CoursesService.removeTagFromCourse(id, deleteModalState.tag._id);
      }

      dispatch(hideLoader());

      dispatch(
        showToast({
          type: 'success',
          text: 'Tag deletada com sucesso',
        }),
      );

      onDebateDeleted && onDebateDeleted();
    } catch (error) {
      handleErrorMessage(error);
      dispatch(hideLoader());
    }
  }, [deleteModalState, id, dispatch, onDebateDeleted, type]);

  if (!deleteModalState.open) {
    return null;
  }

  return (
    <SimplePopUp
      isOpen
      onCancelClick={resetDeleteModal}
      onConfirmClick={handleDelete}
    >
      {' '}
      <span>
        Tem certeza que deseja deletar a tag{' '}
        <span style={{ fontWeight: 'bold' }}>{deleteModalState.tag.name}</span>?
      </span>
    </SimplePopUp>
  );
};

export default forwardRef(RemoveTagModal);
