import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { getStudentList } from './thunkActions';

const studentListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const students = createSlice({
  name: 'students/studentList',
  initialState: studentListAdapter.getInitialState(initialState),
  reducers: {
    resetStudentsList: (state) => {
      studentListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getStudentList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getStudentList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      studentListAdapter.setAll(state, action.payload.students);
    },
    [getStudentList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
  },
});

export { getStudentList };

export const { resetStudentsList } = students.actions;

export const studentListSelectors = studentListAdapter.getSelectors(
  (state) => state.studentList,
);

export const selectStudentListFetchState = (state) =>
  state.studentList.fetchState;

export default students.reducer;
