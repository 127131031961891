import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import {
  getDebatesOfClassroom,
  addClassroomToDebate,
  removeDebateFromClassroom,
} from './thunkActions';

const classroomListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const debatesOfClassroom = createSlice({
  name: 'classroom/debatesOfClassroom',
  initialState: classroomListAdapter.getInitialState(initialState),
  reducers: {
    resetDebatesOfClassroom: (state) => {
      classroomListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getDebatesOfClassroom.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getDebatesOfClassroom.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      classroomListAdapter.setAll(state, action.payload.debates);
    },
    [getDebatesOfClassroom.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },

    [addClassroomToDebate.fulfilled]: (state, action) => {
      classroomListAdapter.addOne(state, action.payload);
    },

    [removeDebateFromClassroom.fulfilled]: (state, action) => {
      classroomListAdapter.removeOne(state, action.payload);
    },
  },
});

export {
  getDebatesOfClassroom,
  addClassroomToDebate,
  removeDebateFromClassroom,
};

export const { resetDebatesOfClassroom } = debatesOfClassroom.actions;

export const debatesOfClassroomSelectors = classroomListAdapter.getSelectors(
  (state) => state.debatesOfClassroom,
);

export const selectDebatesOfClassroomFetchState = (state) =>
  state.debatesOfClassroom.fetchState;

export default debatesOfClassroom.reducer;
