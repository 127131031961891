import React, { useCallback, useRef, useMemo, useState } from 'react';
import {
  Card,
  CardHeader,
  Col,
  Row,
  CardBody,
  Container,
  Button,
} from 'reactstrap';
import { Form } from '@unform/web';
import moment from 'moment';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { DateTimeInput, Input, DisplayField } from '../../../../components';
import { createClassOfCourse } from '../../../../store/courses/subCourseList';
import useValidateField from '../../../../library/hooks/useValidateField';
import navigationKeys from '../../../../library/enums/navigationKeys';
import { validateAll } from '../../../../library/utils/validations';
import { showToast } from '../../../../store/toast';

import SearchTeacherModal from './SearchTeacherModal';

export default function ClassOfCourseDetail() {
  const dispatch = useDispatch();
  const history = useHistory();

  const formRef = useRef();
  const [teacher, setTeacher] = useState(null);

  const match = useRouteMatch();
  const courseId = useMemo(() => match.params.courseId, [match.params]);

  const [searchProfessorModalIsOpen, setSearchProfessorModalIsOpen] = useState(
    false,
  );

  const validateField = useValidateField(formRef);

  const getDate = useCallback((date, time) => {
    const momentTime = moment(time, 'HH:mm');

    return moment(date, 'DD/MM/YYYY')
      .hours(momentTime.hour())
      .minutes(momentTime.minutes())
      .format('YYYY-MM-DDTHH:mm:ss.SSS[z]');
  }, []);

  const handleSubmit = useCallback(
    (form) => {
      const [formErrors, isValid] = validateAll(form);

      if (!isValid) {
        formRef.current.setErrors(formErrors);
        return;
      }

      if (!teacher) {
        dispatch(
          showToast({
            type: 'danger',
            text: 'Professor Obrigatório',
          }),
        );

        return;
      }

      dispatch(
        createClassOfCourse({
          id: courseId,
          teacher: teacher._id,
          date: getDate(form.date, form.time),
          zoomLink: form.zoomLink,
        }),
      ).then((action) => {
        if (action.payload) {
          history.replace(`${navigationKeys.ADMIN_COURSES}/${courseId}`);
        }
      });
    },
    [dispatch, getDate, courseId, history, teacher],
  );

  const handleChooseTeacherClick = useCallback((e) => {
    e.preventDefault();
    setSearchProfessorModalIsOpen(true);
  }, []);

  const handleTeacherChosen = useCallback((teacherChosen) => {
    setTeacher(teacherChosen);
    setSearchProfessorModalIsOpen(false);
  }, []);

  const handleCancelClick = useCallback(
    (e) => {
      e.preventDefault();
      history.replace(`${navigationKeys.ADMIN_COURSES}/${courseId}`);
    },
    [history, courseId],
  );

  return (
    <Container>
      <Col style={{ margin: '20px 0' }}>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Criação de Aula do Course</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col lg="6">
                  <DateTimeInput
                    label="Data"
                    name="date"
                    timeFormat={false}
                    dateFormat="DD/MM/YYYY"
                    closeOnSelect
                    validateField={validateField}
                    placeholder="Selecione a data..."
                  />
                </Col>

                <Col lg="6">
                  <DateTimeInput
                    label="Hora"
                    name="time"
                    viewMode="time"
                    dateFormat={false}
                    timeFormat="HH:mm"
                    validateField={validateField}
                    placeholder="Selecione a hora..."
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <Input
                    validateField={validateField}
                    label="Link da Aula"
                    name="zoomLink"
                    className="form-control-alternative"
                    placeholder="Digite o link..."
                  />
                </Col>
                <Col lg="6">
                  <Row className="ml-1 mr-1">
                    <DisplayField
                      label="Professor"
                      value={teacher ? teacher.name : ''}
                    />
                    <div
                      style={{
                        paddingTop: 40,
                        paddingLeft: 10,
                      }}
                    >
                      <Button
                        color="secondary"
                        onClick={handleChooseTeacherClick}
                      >
                        Escolher
                      </Button>
                    </div>
                  </Row>
                </Col>
              </Row>
              <Col className="text-right mt-4" xs="12">
                <Row className="justify-content-end">
                  <Button
                    color="secondary"
                    size="m"
                    onClick={handleCancelClick}
                  >
                    Cancelar
                  </Button>
                  <Button color="primary" size="m" type="submit">
                    Salvar
                  </Button>
                </Row>
              </Col>
            </Form>
          </CardBody>
        </Card>
      </Col>
      <SearchTeacherModal
        isOpen={searchProfessorModalIsOpen}
        onCancelPress={() => setSearchProfessorModalIsOpen(false)}
        onTeacherChosen={handleTeacherChosen}
      />
    </Container>
  );
}
