import Api from '../library/api';

class AccountService {
  login = (email, password) => {
    return Api.post('/sessions', { email, password });
  };

  forgotPassword = (email) => {
    return Api.post('/password/forgot', { email });
  };

  resetPassword = (body) => {
    return Api.post('/password/reset', body);
  };
}

export default new AccountService();
