import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimes, FaEye } from 'react-icons/fa';
import { Button } from 'reactstrap';

import { useHistory } from 'react-router-dom';
import navigationKeys from '../../../../../library/enums/navigationKeys';
import {
  getDebatesOfClassroom,
  debatesOfClassroomSelectors,
  selectDebatesOfClassroomFetchState,
  addClassroomToDebate,
  removeDebateFromClassroom,
  resetDebatesOfClassroom,
} from '../../../../../store/classrooms/debatesOfClassroom';
import { selectClassroomDetails } from '../../../../../store/classrooms/classroomDetails';
import { TableCard, Modal } from '../../../../../components';
import getStatusText from '../../../../../library/utils/getStatusText';
import formatDate from '../../../../../library/utils/formatDate';

import SearchDebatesModal from './SearchDebatesModal';

import styles from '../styles.module.scss';

const DebatesTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [debateMarkedToRemove, setDebateMarkedToRemove] = useState(null);

  const lsDebates = useSelector(debatesOfClassroomSelectors.selectAll);
  const fetchState = useSelector(selectDebatesOfClassroomFetchState);
  const classroom = useSelector(selectClassroomDetails);

  const [addDebateModalIsOpen, setAddDebateModalIsOpen] = useState(false);

  const classroomId = useMemo(() =>
    classroom && classroom._id ? classroom._id : undefined,
  );

  useEffect(() => {
    if (classroomId) {
      dispatch(getDebatesOfClassroom({ classroomId }));
    }

    return () => {};
  }, [dispatch, classroomId]);

  useEffect(() => {
    return () => {
      dispatch(resetDebatesOfClassroom());
    };
  }, [dispatch]);

  const goToPage = useCallback(
    (pageIndex) => {
      if (classroom) {
        dispatch(
          getDebatesOfClassroom({ pageIndex, classroomId: classroom._id }),
        );
      }
    },
    [dispatch, classroom],
  );

  return (
    <div className={styles.tableContainer}>
      <TableCard
        isLoading={fetchState.isLoading}
        page={fetchState.page}
        totalPages={fetchState.totalPages}
        onPageClick={goToPage}
        onNextPageClick={goToPage}
        onPreviusPageClick={goToPage}
        title="Lista de Debates"
        showHeaderButton
        headerButtonClick={() => setAddDebateModalIsOpen(true)}
        headerButtonText="Adcionar Debate à Turma"
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Título</th>
            <th scope="col">Data</th>
            <th scope="col">Status</th>
            <th>Ocorrido</th>
            <th scope="col">Ver?</th>
            <th scope="col">Remover?</th>
          </tr>
        </thead>
        <tbody>
          {lsDebates.map((debate) => (
            <tr key={debate._id} className={styles.tableLineStudents}>
              <td>{debate.title}</td>
              <td>{formatDate(debate.date)}</td>
              <td>{getStatusText(debate.status)}</td>
              <td>Sim</td>
              <td>
                <FaEye
                  size={18}
                  onClick={() =>
                    history.push(
                      `${navigationKeys.ADMIN_DEBATES}/${debate._id}`,
                    )
                  }
                />
              </td>
              <td>
                <FaTimes
                  size={18}
                  onClick={() => setDebateMarkedToRemove(debate)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </TableCard>
      <SearchDebatesModal
        isOpen={addDebateModalIsOpen}
        onDebateChoosen={(debate) => {
          dispatch(
            addClassroomToDebate({
              debateId: debate._id,
              classroomId: classroom._id,
            }),
          ).then(() => setAddDebateModalIsOpen(false));
        }}
        onCancelPress={() => setAddDebateModalIsOpen(false)}
      />
      <Modal isOpen={debateMarkedToRemove !== null}>
        <div
          style={{
            width: 500,
            height: 200,
            background: '#fafafa',
            fontWeight: '500',
            fontSize: 22,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <div style={{ flex: 1 }}>
            <span>
              Tem certeza que deseja remover o Debate{' '}
              <span style={{ fontWeight: 'bold' }}>
                {debateMarkedToRemove && debateMarkedToRemove.title}
              </span>{' '}
              desta turma?
            </span>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              color="secondary"
              style={{ height: 40, marginTop: 6, marginRight: 8 }}
              onClick={(e) => {
                e.preventDefault();
                setDebateMarkedToRemove(null);
              }}
              size="sm"
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              style={{ height: 40, marginTop: 6, marginRight: 8 }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  removeDebateFromClassroom(debateMarkedToRemove.id),
                ).then((action) => {
                  if (action.payload) {
                    setDebateMarkedToRemove(null);
                  }
                });
              }}
              size="sm"
            >
              Remover
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DebatesTable;
