import { createAsyncThunk } from '@reduxjs/toolkit';

import ClassroomsService from '../../../services/ClassroomsService';
import DebatesService from '../../../services/DebatesService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';
import { showToast } from '../../toast';
import { showLoader, hideLoader } from '../../loader';

export const getDebatesOfClassroom = createAsyncThunk(
  'classrooms/getDebatesOfClassroom',
  async ({ classroomId, ...params }, { dispatch }) => {
    const _params = {};

    const page = (params && params.pageIndex) || 1;

    _params.limit = (params && params.limit) || 10;
    _params.skip = (page - 1) * _params.limit;

    if (params && params.title) {
      _params.title = params.title;
    }

    try {
      const response = await ClassroomsService.getDebatesOfClassroom(
        classroomId,
        _params,
      );

      return {
        ...response.data,
        page,
        limit: _params.limit,
      };
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const addClassroomToDebate = createAsyncThunk(
  'debateDetails/addClassroomToDebate',
  async ({ debateId, classroomId }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await DebatesService.addClassroomToDebate(
        debateId,
        classroomId,
      );

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Debate adcionado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const removeDebateFromClassroom = createAsyncThunk(
  'debateDetails/removeDebateFromClassroom',
  async (debateId, { dispatch }) => {
    try {
      dispatch(showLoader());
      await DebatesService.removeDebateFromClassroom(debateId);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Debate removido com sucesso!',
        }),
      );
      return debateId;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
