import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrash, FaPen } from 'react-icons/fa';

import {
  getTagList,
  tagListSelectors,
  selectTagsListFetchState,
  resetTagssList,
} from '../../../../store/tags/tagsList';
import { TableCard, DisplayStatus } from '../../../../components';
import useFomatDate from '../../../../library/hooks/useFomatDate';

import styles from './styles.module.scss';
import useDebounce from '../../../../library/hooks/useDebounce';

import DeleteTagModal from './DeleteTagModal';
import TagFormModal from './TagFormModal';

const TagssTable = ({ history }) => {
  console.log();
  const dispatch = useDispatch();
  const tagType = useMemo(() => {
    const routePath = history.location.pathname.split('/')[2];
    return routePath === 'course-tags'
      ? { label: 'Curso', type: 'course' }
      : { label: 'Debate', type: 'debate' };
  }, [history.location.pathname]);

  const lsTags = useSelector(tagListSelectors.selectAll);
  const fetchState = useSelector(selectTagsListFetchState);

  const [search, setSearch] = useState('');
  const deleteTagModalRef = useRef(null);
  const tagFormModalRef = useRef(null);

  useDebounce(
    () => dispatch(getTagList({ name: search, type: tagType.type })),
    [search, tagType.type],
    {
      debounceAtStart: false,
    },
  );

  const goToPage = useCallback(
    (pageIndex) => dispatch(getTagList({ pageIndex, type: tagType.type })),
    [dispatch, tagType.type],
  );

  const formatDate = useFomatDate();

  useEffect(() => {
    return () => {
      dispatch(resetTagssList());
    };
  }, [dispatch]);

  return (
    <>
      <div className={styles.backgroundTop} />
      <div className={styles.container}>
        <div className="col">
          <div className="row align-items-center">
            <div>
              <input
                value={search}
                className={styles.search}
                placeholder={`Buscar Tag de ${tagType.label}...`}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <TableCard
            isLoading={fetchState.isLoading}
            page={fetchState.page}
            totalPages={fetchState.totalPages}
            onPageClick={goToPage}
            onNextPageClick={goToPage}
            onPreviusPageClick={goToPage}
            title={`Lista de Tags de ${tagType.label}`}
            showHeaderButton
            headerButtonClick={() => tagFormModalRef.current.create()}
            headerButtonText="Criar Nova Tag"
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Criado Em</th>
                <th scope="col">Atualizado Em</th>
                <th scope="col">Status</th>
                <th scope="col">Editar?</th>
                <th scope="col">Deletar?</th>
              </tr>
            </thead>
            <tbody>
              {lsTags.map((tag) => (
                <tr key={tag._id}>
                  <td>{tag.name}</td>
                  <td>{formatDate(tag.createdAt)}</td>
                  <td>{formatDate(tag.updatedAt)}</td>
                  <td>
                    <DisplayStatus status={tag.status} />
                  </td>
                  <th scope="col">
                    <FaPen
                      className={styles.trashIcon}
                      onClick={() => tagFormModalRef.current.update(tag)}
                    />
                  </th>
                  <td>
                    <FaTrash
                      className={styles.trashIcon}
                      onClick={() => deleteTagModalRef.current.open(tag)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCard>
        </div>
      </div>

      <DeleteTagModal ref={deleteTagModalRef} />
      <TagFormModal ref={tagFormModalRef} />
    </>
  );
};

export default TagssTable;
