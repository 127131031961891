import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';
import { css } from 'glamor';
import useOnChangeText from '../../library/hooks/useOnChangeText';

import styles from './input.module.scss';

// reactstrap components
// core components
export default function Input({
  name,
  validateField,
  label = '',
  onChange,
  ...inputProps
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const onChangeText = useOnChangeText(
    inputRef,
    fieldName,
    error,
    validateField,
  );

  const rule = css({
    '::placeholder': {
      color: '#adb5bd',
      fontSize: 14,
    },
  });

  return (
    <div className={styles.container}>
      <label className="form-control-label">{label}</label>
      <InputMask
        {...rule}
        style={{
          padding: '10px 10px',
          borderRadius: 5,
          color: '#8898aa',
          fontSize: 16,
        }}
        onBlur={() => validateField && validateField(fieldName)}
        onChange={(e) => {
          onChange && onChange(e);
          onChangeText(e.target.value);
        }}
        ref={inputRef}
        defaultValue={defaultValue}
        {...inputProps}
      />

      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  );
}
