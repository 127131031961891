import { createSlice } from '@reduxjs/toolkit';

import { getAdminDetails, updateAdminById, createAdmin } from './thunkActions';

const initialState = {
  data: null,
  fetchState: {
    isLoading: false,
    hasError: false,
  },
};

const adminDetails = createSlice({
  name: 'root/adminDetails',
  initialState,
  reducers: {
    resetAdminDetails: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getAdminDetails.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getAdminDetails.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.data = action.payload;
    },
    [getAdminDetails.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },

    [updateAdminById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
    },

    [createAdmin.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export { getAdminDetails, updateAdminById, createAdmin };

export const { resetAdminDetails } = adminDetails.actions;

export const selectAdminDetails = (state) => state.adminsDetails.data;

export const selectAdminDetailsFetchState = (state) =>
  state.adminsDetails.fetchState;

export default adminDetails.reducer;
