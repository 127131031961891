import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { getClassroomList } from './thunkActions';

const classroomListAdapter = createEntityAdapter();

const initialState = classroomListAdapter.getInitialState({
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
});

const configs = createSlice({
  name: 'root/classroomList',
  initialState,
  extraReducers: {
    [getClassroomList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getClassroomList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      classroomListAdapter.setAll(state, action.payload.classrooms);
    },
    [getClassroomList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
  },
});

export { getClassroomList };

export const classroomListSelectors = classroomListAdapter.getSelectors(
  (state) => state.classroomList,
);

export const selectClassroomListFetchState = (state) =>
  state.classroomList.fetchState;

export default configs.reducer;
