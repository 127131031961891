import React from 'react';

import styles from './styles.module.scss';

export default function DisplayField({ label = '', value = '' }) {
  return (
    <div className={styles.container}>
      <label className="form-control-label">{label}</label>
      <span>{value}</span>
    </div>
  );
}
