import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectIsUserLogged } from '../store/loggedUser';
import Login from '../pages/auth/Login';
import ForgotPasswordForm from '../pages/auth/ForgotPasswordForm';
import ResetPasswordForm from '../pages/auth/ResetPasswordForm';

export default function AuthRoutes() {
  const loggedUser = useSelector(selectIsUserLogged);

  if (loggedUser) return <Redirect to="/admin" />;

  return (
    <>
      <Switch>
        <Route path="/auth/login" exact component={Login} />
        <Route
          path="/auth/forgot-password"
          exact
          component={ForgotPasswordForm}
        />
        <Route
          path="/auth/reset-password"
          exact
          component={ResetPasswordForm}
        />
        <Redirect from="/auth" exact to="/auth/login" />
      </Switch>
    </>
  );
}
