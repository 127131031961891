import React, { useMemo } from 'react';
import {
  Card,
  CardHeader,
  Table,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Pagination,
  Col,
  Button,
  Row,
} from 'reactstrap';

import _ from 'lodash';
import { FaAngleLeft, FaAngleRight, FaEllipsisH } from 'react-icons/fa';

import ContentLoader from '../ContentLoader';

const TableCard = ({
  children,
  page = 1,
  isLoading = false,
  totalPages,
  onPreviusPageClick,
  onNextPageClick,
  onPageClick,
  showHeaderButton,
  headerButtonText,
  headerButtonClick,
  headerComponent,
  contentLoaderStyle,
  title = '',
  style,
}) => {
  const firstPage = useMemo(() => page === 1, [page]);
  const lastPage = useMemo(() => totalPages === page, [totalPages, page]);

  return (
    <Card className="shadow" style={style}>
      {headerComponent || (
        <CardHeader className="border-0">
          <Row>
            <Col>
              <h3 className="mb-0">{title}</h3>
            </Col>
            {showHeaderButton && (
              <Col className="text-right" xs="4">
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    headerButtonClick();
                  }}
                  size="sm"
                >
                  {headerButtonText}
                </Button>
              </Col>
            )}
          </Row>
        </CardHeader>
      )}
      {isLoading ? (
        <ContentLoader isLoading style={contentLoaderStyle} />
      ) : (
        <Table className="align-items-center table-flush" responsive>
          {children}
        </Table>
      )}
      <CardFooter className="py-4">
        {totalPages && (
          <nav aria-label="...">
            <Pagination
              className="pagination justify-content-end mb-0"
              listClassName="justify-content-end mb-0"
            >
              <PaginationItem
                disabled={firstPage}
                style={{ opacity: firstPage ? 0.3 : 1 }}
              >
                <PaginationLink
                  onClick={(event) => {
                    event.preventDefault();
                    onPreviusPageClick(page - 1);
                  }}
                  tabIndex="-1"
                >
                  <FaAngleLeft />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>
              {_.range(page - 4 < 1 ? 1 : page - 4, page).map((pageIndex) => (
                <PaginationItem
                  key={pageIndex}
                  className={pageIndex === page ? 'active' : ''}
                >
                  <PaginationLink
                    onClick={(event) => {
                      event.preventDefault();
                      onPageClick(pageIndex);
                    }}
                  >
                    {pageIndex}
                  </PaginationLink>
                </PaginationItem>
              ))}
              {_.range(
                page,
                page + 4 > totalPages ? totalPages + 1 : page + 5,
              ).map((pageIndex) => (
                <PaginationItem
                  key={pageIndex}
                  className={pageIndex === page ? 'active' : ''}
                >
                  <PaginationLink
                    onClick={(event) => {
                      event.preventDefault();
                      onPageClick(pageIndex);
                    }}
                  >
                    {pageIndex}
                  </PaginationLink>
                </PaginationItem>
              ))}
              {page + 5 < totalPages && (
                <FaEllipsisH
                  color="#7986a6"
                  size={18}
                  style={{ alignSelf: 'flex-end', margin: '0 5px' }}
                />
              )}
              {page + 4 < totalPages && (
                <PaginationItem
                  key={totalPages}
                  className={totalPages === page ? 'active' : ''}
                >
                  <PaginationLink
                    onClick={(event) => {
                      event.preventDefault();
                      onPageClick(totalPages);
                    }}
                  >
                    {totalPages}
                  </PaginationLink>
                </PaginationItem>
              )}
              <PaginationItem
                disabled={lastPage}
                style={{
                  opacity: lastPage ? 0.3 : 1,
                }}
              >
                <PaginationLink
                  onClick={(event) => {
                    event.preventDefault();
                    onNextPageClick(page + 1);
                  }}
                  tabIndex="-1"
                >
                  <FaAngleRight />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </nav>
        )}
      </CardFooter>
    </Card>
  );
};

export default TableCard;
