export default function getFormInitialData(form, defaulValue) {
  if (!defaulValue) {
    return form;
  }

  const newForm = {};

  Object.keys(form).map((key) => {
    newForm[key] = defaulValue[key] ? defaulValue[key] : '';
  });

  return newForm;
}
