import { createAsyncThunk } from '@reduxjs/toolkit';
import { showToast } from '../../toast';
import { showLoader, hideLoader } from '../../loader';
import StudentsService from '../../../services/StudentService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';

export const getStudentDetails = createAsyncThunk(
  'studentDetails/getStudentDetails',
  async (studentId, { dispatch }) => {
    try {
      const response = await StudentsService.getStudentById(studentId);

      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const updateStudentById = createAsyncThunk(
  'studentDetails/updateStudentById',
  async ({ id, ...rest }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await StudentsService.updateStudentById(id, rest);
      dispatch(hideLoader());

      dispatch(
        showToast({
          type: 'success',
          text: 'Aluno Atualizado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createStudent = createAsyncThunk(
  'studentDetails/createStudent',
  async (student, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await StudentsService.createStudent(student);

      dispatch(
        showToast({
          type: 'success',
          text: 'Aluno Criado com sucesso!',
        }),
      );

      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const deleteStudent = createAsyncThunk(
  'studentDetails/deleteMediator',
  async (studentId, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await StudentsService.deleteStudent(studentId);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Aluno deletado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
