import { useMemo } from 'react';
import getFormInitialData from '../utils/getFormInitialData';

export default (form, defaultValue) =>
  useMemo(() => {
    if (form) {
      return getFormInitialData(form, defaultValue);
    }
    return {};
  }, [defaultValue, form]);
