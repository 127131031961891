import { createAsyncThunk } from '@reduxjs/toolkit';
import { showToast } from '../../toast';
import { showLoader, hideLoader } from '../../loader';
import MediatorsService from '../../../services/MediatorService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';

export const getMediatorDetails = createAsyncThunk(
  'mediatorsDetails/getMediatorDetails',
  async (mediatorsId, { dispatch }) => {
    try {
      const response = await MediatorsService.getMediatorById(mediatorsId);

      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const updateMediatorById = createAsyncThunk(
  'mediatorDetails/updateMediatorById',
  async ({ id, ...rest }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await MediatorsService.updateMediatorById(id, rest);

      dispatch(hideLoader());

      dispatch(
        showToast({
          type: 'success',
          text: 'Mediador Atualizado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createMediator = createAsyncThunk(
  'mediatorDetails/createMediator',
  async (mediator, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await MediatorsService.createMediator(mediator);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Mediador Criado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const deleteMediator = createAsyncThunk(
  'mediatorDetails/deleteMediator',
  async (mediatorId, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await MediatorsService.deleteMediator(mediatorId);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Mediador deletado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
