import { combineReducers } from 'redux';

import loggedUser from './loggedUser';
import loader from './loader';
import toast from './toast';
import configs from './configs';

import adminsList from './admins/adminsList';
import adminsDetails from './admins/adminsDetails';

import mediatorList from './mediators/mediatorList';
import mediatorDetails from './mediators/mediatorDetails';

import professorDetails from './professors/professorDetails';
import professorList from './professors/professorList';

import studentDetails from './students/studentDetails';
import studentList from './students/studentList';

import debateList from './debates/debateList';
import debateDetails from './debates/debateDetails';
import subDebateList from './debates/subDebateList';

import subscriptionDetails from './subscriptions/subscriptionDetails';
import subscriptionList from './subscriptions/subscriptionList';

import classroomList from './classrooms/classroomList';
import classroomDetails from './classrooms/classroomDetails';
import debatesOfClassroom from './classrooms/debatesOfClassroom';

import newslettersList from './newsletters/newslettersList';

import formsList from './forms/formsList';

import tagsList from './tags/tagsList';

import courseList from './courses/courseList';
import courseDetails from './courses/courseDetails';
import subCourseList from './courses/subCourseList';

export default combineReducers({
  loggedUser,
  loader,
  toast,
  configs,
  adminsList,
  adminsDetails,
  mediatorList,
  mediatorDetails,
  professorDetails,
  professorList,
  studentDetails,
  studentList,
  debateList,
  debateDetails,
  subscriptionDetails,
  subscriptionList,
  classroomList,
  classroomDetails,
  debatesOfClassroom,
  subDebateList,
  newslettersList,
  formsList,
  tagsList,
  courseList,
  courseDetails,
  subCourseList,
});
