import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import {
  getSubCourseList,
  createClassOfCourse,
  deleteClassOfCourse,
} from './thunkActions';

const subCourseListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const subCourses = createSlice({
  name: 'subCourses/subCourseList',
  initialState: subCourseListAdapter.getInitialState(initialState),
  reducers: {
    resetCoursesList: (state) => {
      subCourseListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getSubCourseList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getSubCourseList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      subCourseListAdapter.setAll(state, action.payload.data);
    },
    [getSubCourseList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
    [deleteClassOfCourse.fulfilled]: (state, action) => {
      subCourseListAdapter.removeOne(state, action.payload);
    },
  },
});

export { getSubCourseList, createClassOfCourse, deleteClassOfCourse };

export const { resetCoursesList } = subCourses.actions;

export const subCourseListSelectors = subCourseListAdapter.getSelectors(
  (state) => state.subCourseList,
);

export const selectCourseListFetchState = (state) =>
  state.subCourseList.fetchState;

export default subCourses.reducer;
