import { createSlice } from '@reduxjs/toolkit';

import {
  getCourseDetails,
  updateCourseById,
  createCourse,
  deleteCourse,
} from './thunkActions';

const initialState = {
  data: null,
  fetchState: {
    isLoading: false,
    hasError: false,
  },
};

const courseDetails = createSlice({
  name: 'root/courseDetails',
  initialState,
  reducers: {
    resetCourse: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getCourseDetails.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getCourseDetails.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.data = action.payload;
    },
    [getCourseDetails.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },

    [updateCourseById.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [updateCourseById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
    },
    [updateCourseById.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },

    [createCourse.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export { getCourseDetails, updateCourseById, createCourse, deleteCourse };

export const selectCourseDetails = (state) => state.courseDetails.data;

export const selectCourseDetailsFetchState = (state) =>
  state.courseDetails.fetchState;

export const { resetCourse } = courseDetails.actions;

export default courseDetails.reducer;
