import React from 'react';
import Spinner from 'react-spinkit';
import { useSelector } from 'react-redux';

import styles from './loading.module.scss';

import { selectLoaderIsLoading } from '../../store/loader';

function Loading() {
  const isLoading = useSelector(selectLoaderIsLoading);

  if (!isLoading) {
    document.body.style.overflow = 'visible';

    return null;
  }

  document.body.style.overflow = 'hidden';
  return (
    <div className={styles.loading_overlay}>
      <Spinner
        name="line-scale-party"
        color="orange"
        className={styles.loading_icon}
        fadeIn="quarter"
      />
    </div>
  );
}

export default Loading;
