/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState, useCallback } from 'react';
import { FaFilePdf, FaTrash } from 'react-icons/fa';

import styles from './styles.module.scss';

export default function UploadInput({
  label = '',
  onChange,
  defaultValue,
  accept = '',
}) {
  const uploadInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [imagePath, setImagePath] = useState('');

  const handleFilePick = useCallback(
    (e) => {
      const _file = e.target.files[0];
      setFile(_file);
      setImagePath(URL.createObjectURL(_file));
      onChange && onChange(_file);
    },
    [onChange],
  );

  const PreviewFileOrImage = useCallback(() => {
    if (!file && !defaultValue) {
      return null;
    }

    if (accept.split('/')[0] === 'image') {
      return (
        <img src={imagePath || defaultValue.path} className={styles.image} />
      );
    }

    return (
      <div className={styles.pdfFileContainer}>
        <FaFilePdf />
        <p>{(file && file.name) || defaultValue.label}</p>
        <FaTrash onClick={() => setFile(null)} className={styles.trashIcon} />
      </div>
    );
  }, [file, defaultValue, accept, imagePath]);

  return (
    <div className={styles.container}>
      <label className="form-control-label">{label}</label>
      <PreviewFileOrImage />
      <button
        className={styles.uploadButton}
        type="button"
        onClick={() => {
          uploadInputRef.current.click();
        }}
      >
        Escolher Arquivo
      </button>
      <input
        accept={accept}
        ref={uploadInputRef}
        className={styles.uploadInput}
        type="file"
        onChange={handleFilePick}
      />
    </div>
  );
}
