import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import {
  getTagList,
  deleteTag,
  createTag,
  updateTagById,
} from './thunkActions';

const tagListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const tags = createSlice({
  name: 'root/tagList',
  initialState: tagListAdapter.getInitialState(initialState),
  reducers: {
    resetTagssList: (state) => {
      tagListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getTagList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getTagList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      tagListAdapter.setAll(state, action.payload.data);
    },
    [getTagList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
    [deleteTag.fulfilled]: (state, action) => {
      tagListAdapter.removeOne(state, action.payload);
    },
  },
});

export { getTagList, deleteTag, createTag, updateTagById };

export const { resetTagssList } = tags.actions;

export const tagListSelectors = tagListAdapter.getSelectors(
  (state) => state.tagsList,
);

export const selectTagsListFetchState = (state) => state.tagsList.fetchState;

export default tags.reducer;
