import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import {
  getSubDebateList,
  createClassOfDebate,
  deleteClassOfDebate,
} from './thunkActions';

const subDebateListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const subDebates = createSlice({
  name: 'subDebates/subDebateList',
  initialState: subDebateListAdapter.getInitialState(initialState),
  reducers: {
    resetDebatesList: (state) => {
      subDebateListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getSubDebateList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getSubDebateList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      subDebateListAdapter.setAll(state, action.payload.classes);
    },
    [getSubDebateList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
    [deleteClassOfDebate.fulfilled]: (state, action) => {
      subDebateListAdapter.removeOne(state, action.payload);
    },
  },
});

export { getSubDebateList, createClassOfDebate, deleteClassOfDebate };

export const { resetDebatesList } = subDebates.actions;

export const subDebateListSelectors = subDebateListAdapter.getSelectors(
  (state) => state.subDebateList,
);

export const selectDebateListFetchState = (state) =>
  state.subDebateList.fetchState;

export default subDebates.reducer;
