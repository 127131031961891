import Api from '../library/api';

class TagsService {
  getTagList = (params) =>
    Api.get('/tags', {
      params,
    });

  getTagById = (tagId) => Api.get(`/tags/${tagId}`);

  updateTagById(tagId, params) {
    return Api.put(`/tags/${tagId}`, params);
  }

  createTag(tag) {
    // return new Promise((resolve) => setTimeout(() => resolve(), 500));
    return Api.post('/tags', tag);
  }

  deleteTag(tagId) {
    return Api.delete('/tags', { data: { id: tagId } });
  }
}

export default new TagsService();
