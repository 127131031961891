import React, { useEffect, useMemo } from 'react';
import { Container, Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import ContentLoader from '../../../../components/ContentLoader';
import {
  selectClassroomDetailsFetchState,
  getClassroomDetails,
  resetClassroom,
} from '../../../../store/classrooms/classroomDetails';
import DebatesOfClassroomTable from './DebatesOfClassroomTable';
import StudentsOfClassroomTable from './StudentsOfClassroomTable';
import ClassroomDetailsCard from './ClassroomDetailsCard';

export default function ClassroomDetail({ match }) {
  const classroomId = useMemo(() => match.params.classroomId, [match.params]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (classroomId) {
      dispatch(getClassroomDetails(classroomId));
    }
    return () => {
      dispatch(resetClassroom());
    };
  }, [classroomId, dispatch]);

  const fetchState = useSelector(selectClassroomDetailsFetchState);

  return (
    <Container>
      <Col>
        {fetchState.isLoading ? (
          <ContentLoader isLoading />
        ) : (
          <>
            <ClassroomDetailsCard />

            {classroomId && <StudentsOfClassroomTable />}
          </>
        )}

        {classroomId && <DebatesOfClassroomTable />}
      </Col>
    </Container>
  );
}
