import api from './index';

export const tokenIterceptor = (token, resetUser) => {
  api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        resetUser();
      }

      return Promise.reject(error);
    },
  );
};
