import Api from '../library/api';

class ClassroomsService {
  getClassroomList = (params) =>
    Api.get('/classrooms', {
      params,
    });

  getClassroomById = (userId) => Api.get(`/classrooms/${userId}`);

  updateClassroomById(userId, params) {
    return Api.put(`/classrooms/${userId}`, params);
  }

  createClassroom(classroom) {
    // return new Promise((resolve) => setTimeout(() => resolve(), 500));
    return Api.post('/classrooms', classroom);
  }

  addStudentToClassroom(classroomId, student_id) {
    return Api.patch(`/classrooms/students/${classroomId}`, { student_id });
  }

  removeStudentFromClassroom(classroom_id, student_id) {
    return Api.delete(`/classrooms/students`, {
      data: { classroom_id, student_id },
    });
  }

  getDebatesOfClassroom(classroom_id, params) {
    return Api.get(`/classrooms/${classroom_id}/debates`, {
      params: {
        status: true,
        ...params,
      },
    });
  }
}

export default new ClassroomsService();
