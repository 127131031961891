import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import YouTube from 'react-youtube';
import _ from 'lodash';
import moment from 'moment';

import useValidateField from '../../../../library/hooks/useValidateField';
import { validateAll } from '../../../../library/utils/validations';
import { showToast } from '../../../../store/toast';

import {
  ContentLoader,
  Input,
  RadioList,
  UploadInput,
  TextArea,
  CheckBox,
  SimplePopUp,
  ChooseTagsTable,
} from '../../../../components';

import {
  selectDebateDetails,
  selectDebateDetailsFetchState,
  getDebateDetails,
  updateDebateById,
  resetDebate,
  createDebate,
  deleteDebate,
} from '../../../../store/debates/debateDetails';
import navigationKeys from '../../../../library/enums/navigationKeys';
import useFomInitialData from '../../../../library/hooks/useFomInitialData';
// import formatDate from '../../../../library/utils/formatDate';

import SubDebatesTable from './SubDebatesTable';

// import styles from './debate-detail.module.scss';

export default function DebateDetail({ match, history }) {
  const debateId = useMemo(() => match.params.debateId, [match.params]);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [
    confirmDebateRemovePopUpOpen,
    setconfirmDebateRemovePopUpOpen,
  ] = useState(false);

  const fetchState = useSelector(selectDebateDetailsFetchState);
  const debate = useSelector(selectDebateDetails);

  const [debateStatus, setUserStatus] = useState(null);
  const [category, setCategory] = useState('');
  const [material, setMaterial] = useState(null);
  const [material2, setMaterial2] = useState(null);
  const [image, setImage] = useState(null);
  const [top50, setTop50] = useState(false);

  const [materialDefaultValue, setMaterialDefaultValue] = useState(null);
  const [material2DefaultValue, setMaterial2DefaultValue] = useState(null);
  const [imageDefaultValue, setImageDefaultValue] = useState(null);

  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    if (debate) {
      debate.get_image &&
        setImageDefaultValue({
          label: debate.imageOriginalName,
          path: debate.get_image,
        });
      debate.get_material &&
        setMaterialDefaultValue({
          label: debate.materialOriginalName,
          path: debate.get_material,
        });
      debate.get_material2 &&
        setMaterial2DefaultValue({
          label: debate.material2OriginalName,
          path: debate.get_material2,
        });

      if (debate.category === 'Grupo') {
        setCategory('Grupo');
      } else if (debate.category === 'Individual') {
        setCategory('Individual');
      }
      setUserStatus(debate.status);
      setVideoId(debate.video);
      setTop50(debate.top50);
    }
  }, [debate]);

  const getDate = useCallback((date, time) => {
    const momentTime = moment(time, 'HH:mm');

    return moment(date, 'DD/MM/YYYY')
      .hours(momentTime.hour())
      .minutes(momentTime.minutes())
      .format('YYYY-MM-DDTHH:mm:ss.SSS[z]');
  }, []);

  const initialData = useFomInitialData(
    {
      title: '',
      category: '',
      status: '',
      video: '',
      description: '',
    },
    debate,
  );

  const validateField = useValidateField(formRef);

  useEffect(() => {
    if (debateId) {
      dispatch(getDebateDetails(debateId));
    }
    return () => {
      dispatch(resetDebate());
    };
  }, [debateId, dispatch]);

  const handleSubmit = useCallback(
    (form) => {
      if (debateStatus === null) {
        dispatch(
          showToast({
            type: 'danger',
            text: 'Status Obrigatório',
          }),
        );

        return;
      }

      if (category === '') {
        dispatch(
          showToast({
            type: 'danger',
            text: 'Categoria Obrigatório',
          }),
        );

        return;
      }

      if (!debateId) {
        const [formErrors, isValid] = validateAll(form);

        if (isValid) {
          const data = new FormData();

          data.append('title', form.title);
          data.append('category', category);
          data.append('description', form.description);
          data.append('video', form.video);
          data.append('status', debateStatus);
          data.append('top50', top50);
          material && data.append('material', material, material.name);
          material2 && data.append('material2', material2, material2.name);
          image && data.append('image', image, image.name);

          dispatch(createDebate(data)).then((action) => {
            if (action.payload && action.payload.id) {
              history.push(
                `${navigationKeys.ADMIN_DEBATES}/${action.payload.id}`,
              );
            }
          });
        }
        formRef.current.setErrors(formErrors);

        return;
      }

      const validForm = {};

      Object.keys(form).map((key) => {
        if (form[key]) {
          validForm[key] = form[key];
        }
      });

      const [formErrors, isValid] = validateAll(validForm);

      if (isValid) {
        if (Object.keys(validForm).length === 0) {
          dispatch(
            showToast({
              type: 'info',
              text: 'Nenhuma alteração encontrada',
            }),
          );

          return;
        }

        validForm.date = getDate(form.date, form.hour);

        delete validForm.time;

        const data = new FormData();

        console.log('form', form);

        data.append('title', form.title);
        data.append('description', form.description);
        category && data.append('category', category);
        // data.append('date', getDate(form.date, form.time));
        data.append('video', form.video);
        debateStatus !== null && data.append('status', debateStatus);
        material && data.append('material', material, material.name);
        data.append('hasMaterial', !!material);
        material2 && data.append('material2', material2, material2.name);
        data.append('hasMaterial2', !!material2);
        image && data.append('image', image, image.name);
        data.append('hasImage', !!image);
        data.append('top50', top50);

        dispatch(updateDebateById({ id: debate.id, formData: data }));
        return;
      }

      formRef.current.setErrors(formErrors);
    },
    [
      dispatch,
      debate,
      formRef,
      debateId,
      history,
      debateStatus,
      material,
      image,
      category,
      getDate,
      material2,
      top50,
    ],
  );

  const handleVideoIdChange = useCallback(
    _.debounce((value) => setVideoId(value), 700),
    [],
  );
  if (fetchState.isLoading) {
    return <ContentLoader isLoading />;
  }

  return (
    <Container>
      <Col style={{ margin: '20px 0' }}>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {debateId ? 'Informações de Debate' : 'Criação de Debate'}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={handleSubmit}
              ref={formRef}
              initialData={initialData}
            >
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Título"
                      name="title"
                      className="form-control-alternative"
                      placeholder="Digite o nome..."
                    />
                  </Col>

                  <Col lg="6">
                    <RadioList
                      onValueChange={(value) => setCategory(value)}
                      value={category}
                      title="Tipo"
                      options={[
                        { label: 'Em grupo', value: 'Grupo' },
                        { label: 'Individual', value: 'Individual' },
                      ]}
                    />
                  </Col>

                  <Col lg="6">
                    <RadioList
                      onValueChange={(value) => setUserStatus(value)}
                      value={debateStatus}
                      title="Status"
                      options={[
                        { label: 'Ativo', value: true },
                        { label: 'Inativo', value: false },
                      ]}
                    />
                  </Col>

                  <Col lg="6">
                    <UploadInput
                      accept="application/pdf"
                      label="Arquivo da Aula 1"
                      onChange={(file) => setMaterial(file)}
                      defaultValue={materialDefaultValue}
                    />
                  </Col>

                  <Col lg="6">
                    <UploadInput
                      accept="application/pdf"
                      label="Arquivo da Aula 2"
                      onChange={(file) => setMaterial2(file)}
                      defaultValue={material2DefaultValue}
                    />
                  </Col>

                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Vídeo"
                      name="video"
                      className="form-control-alternative"
                      placeholder="Digite o id do vídeo"
                      onChange={(e) => {
                        handleVideoIdChange(e.target.value);
                      }}
                    />
                  </Col>

                  <Col lg="6" style={{ margin: '20px 0' }}>
                    {videoId && (
                      <YouTube
                        videoId={videoId}
                        opts={{
                          height: '250',
                          width: '400',
                        }}
                      />
                    )}
                  </Col>

                  <Col lg="6">
                    <UploadInput
                      accept="image/*"
                      label="Imagem da Aula"
                      onChange={(file) => setImage(file)}
                      defaultValue={imageDefaultValue}
                    />
                  </Col>

                  <Col lg="6" style={{ marginTop: 0 }}>
                    <label className="form-control-label">Outras Opções</label>
                    <CheckBox
                      text="Top 50"
                      value={top50}
                      onClick={() => setTop50(!top50)}
                    />
                  </Col>

                  <Col lg="12">
                    <TextArea
                      rows={10}
                      validateField={validateField}
                      label="Descrição"
                      name="description"
                      className="form-control-alternative"
                      placeholder="Digite a descrição..."
                    />
                  </Col>
                </Row>
              </div>
              <Col className="text-right" xs="12">
                <Row className="justify-content-end">
                  {debate && (
                    <Button
                      color="secondary"
                      size="m"
                      onClick={(e) => {
                        e.preventDefault();
                        setconfirmDebateRemovePopUpOpen(true);
                      }}
                    >
                      Deletar
                    </Button>
                  )}
                  <Button color="primary" size="m" type="submit">
                    Salvar
                  </Button>
                </Row>
              </Col>
            </Form>
          </CardBody>
        </Card>

        {debate && <SubDebatesTable />}
        {debate && <ChooseTagsTable id={debate._id} type="debate" />}
      </Col>

      {debate && (
        <SimplePopUp
          isOpen={confirmDebateRemovePopUpOpen}
          onCancelClick={() => setconfirmDebateRemovePopUpOpen(false)}
          onConfirmClick={() =>
            dispatch(deleteDebate(debate._id)).then((action) => {
              if (!action.error) {
                history.replace('/admin/debates');
                return;
              }

              setconfirmDebateRemovePopUpOpen(true);
            })
          }
        >
          <span>
            Tem certeza que deseja deletar o debate{' '}
            <span style={{ fontWeight: 'bold' }}>{debate.title}</span>?
          </span>
        </SimplePopUp>
      )}
    </Container>
  );
}
