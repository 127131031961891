import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { css } from 'glamor';
import ReactDatetime from 'react-datetime';

import styles from './styles.module.scss';

export default function Datetime({
  name,
  validateField,
  label = '',
  onChange,
  placeholder,
  ...inputProps
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const rule = css({
    '::placeholder': {
      color: '#adb5bd',
      fontSize: 14,
    },
  });

  return (
    <div className={styles.container}>
      <label className="form-control-label">{label}</label>
      <ReactDatetime
        locale="pt-br"
        inputProps={{
          onKeyDown: (e) => {
            e.preventDefault();
          },
          ref: inputRef,
          /* onBlur: () => validateField && validateField(fieldName),
          onChange: (e) => {
            onChange && onChange(e);
            onChangeText(e.target.value);
          }, */
          placeholder,
        }}
        {...rule}
        defaultValue={defaultValue}
        {...inputProps}
        className={styles.dateTime}
        onChange={() =>
          validateField && setTimeout(() => validateField(fieldName), 200)
        }
        onBlur={() =>
          validateField && setTimeout(() => validateField(fieldName), 200)
        }
      />

      {!!error && <p className={styles.error}>{error}</p>}
    </div>
  );
}
