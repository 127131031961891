import { createAsyncThunk } from '@reduxjs/toolkit';

import AdminsService from '../../../services/AdminsService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';
import { showToast } from '../../toast';
import { showLoader, hideLoader } from '../../loader';

export const getAdminDetails = createAsyncThunk(
  'userDetails/getAdminDetails',
  async (userId, { dispatch }) => {
    try {
      const response = await AdminsService.getAdminById(userId);
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const updateAdminById = createAsyncThunk(
  'adminDetails/updateAdminById',
  async ({ id, ...rest }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await AdminsService.updateAdminById(id, rest);

      dispatch(hideLoader());

      dispatch(
        showToast({
          type: 'success',
          text: 'Admin Atualizado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createAdmin = createAsyncThunk(
  'adminDetails/createAdmin',
  async (admin, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await AdminsService.createAdmin(admin);

      dispatch(hideLoader());

      dispatch(
        showToast({
          type: 'success',
          text: 'Admin criado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
