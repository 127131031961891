import { createSlice } from '@reduxjs/toolkit';

import {
  getSubscriptionDetails,
  updateSubscriptionById,
  createSubscription,
} from './thunkActions';

const initialState = {
  data: null,
  fetchState: {
    isLoading: false,
    hasError: false,
  },
};

const subscriptionDetails = createSlice({
  name: 'root/subscriptionDetails',
  initialState,
  reducers: {
    resetSubscription: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getSubscriptionDetails.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getSubscriptionDetails.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.data = action.payload;
    },
    [getSubscriptionDetails.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },

    [updateSubscriptionById.fulfilled]: (state, action) => {
      state.data = action.payload;
    },

    [createSubscription.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export { getSubscriptionDetails, updateSubscriptionById, createSubscription };

export const selectSubscriptionDetails = (state) =>
  state.subscriptionDetails.data;

export const selectSubscriptionDetailsFetchState = (state) =>
  state.subscriptionDetails.fetchState;

export const { resetSubscription } = subscriptionDetails.actions;

export default subscriptionDetails.reducer;
