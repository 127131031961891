import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';

// import useValidateField from '../../../../library/hooks/useValidateField';
import { validateAll } from '../../../../library/utils/validations';
import { showToast } from '../../../../store/toast';
import { DisplayField, ContentLoader } from '../../../../components';
import {
  selectSubscriptionDetails,
  selectSubscriptionDetailsFetchState,
  getSubscriptionDetails,
  updateSubscriptionById,
  resetSubscription,
  createSubscription,
} from '../../../../store/subscriptions/subscriptionDetails';
import navigationKeys from '../../../../library/enums/navigationKeys';
import useFomInitialData from '../../../../library/hooks/useFomInitialData';

export default function SubscriptionDetail({ match, history }) {
  const userId = useMemo(() => match.params.userId, [match.params]);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const fetchState = useSelector(selectSubscriptionDetailsFetchState);
  const subscription = useSelector(selectSubscriptionDetails);

  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    if (subscription) {
      setUserStatus(subscription.status);
    }
  }, [subscription]);

  const initialData = useFomInitialData(
    {
      name: '',
      email: '',
      surname: '',
      phone: '',
    },
    subscription,
  );

  // const validateField = useValidateField(formRef);

  useEffect(() => {
    if (userId) {
      dispatch(getSubscriptionDetails(userId));
    }
    return () => {
      dispatch(resetSubscription());
    };
  }, [userId, dispatch]);

  const handleSubmit = useCallback(
    (form) => {
      if (userStatus === null) {
        dispatch(
          showToast({
            type: 'danger',
            text: 'Status Obrigatório',
          }),
        );

        return;
      }

      if (!userId) {
        const [formErrors, isValid] = validateAll(form);

        const newSubscription = {
          name: form.name,
          phone: form.phone,
          surname: form.surname,
          password: form.newPassword,
          email: form.email,
        };

        if (isValid) {
          dispatch(createSubscription(newSubscription)).then((action) => {
            if (action.payload && action.payload.id) {
              history.push(
                `${navigationKeys.ADMIN_MEDIATORS}/${action.payload.id}`,
              );
            }
          });
        } else {
          formRef.current.setErrors(formErrors);
        }

        return;
      }

      const validForm = {};

      Object.keys(form).map((key) => {
        if (form[key]) {
          validForm[key] = form[key];
        }
      });

      const [formErrors, isValid] = validateAll(validForm);

      if (isValid) {
        if (Object.keys(validForm).length === 0) {
          dispatch(
            showToast({
              type: 'info',
              text: 'Nenhuma alteração encontrada',
            }),
          );

          return;
        }

        dispatch(
          updateSubscriptionById({
            id: subscription.id,
            status: userStatus,
            ...form,
          }),
        );
        return;
      }

      formRef.current.setErrors(formErrors);
    },
    [dispatch, subscription, formRef, userId, history, userStatus],
  );

  if (fetchState.isLoading) {
    return <ContentLoader isLoading />;
  }

  return (
    <Container>
      <Col style={{ margin: '20px 0' }}>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Informações de Assinatura</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={handleSubmit}
              ref={formRef}
              initialData={initialData}
            >
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <DisplayField
                      label="Nome de usuário"
                      value="Anderson Mock"
                    />
                  </Col>
                  <Col lg="6">
                    <DisplayField label="Plano" value="Mockado" />
                  </Col>
                  <Col lg="6">
                    <DisplayField label="Status de pagamento" value="Devendo" />
                  </Col>
                  <Col lg="6">
                    <DisplayField label="Vencimento" value="10/10/2005" />
                  </Col>
                  <Col lg="6">
                    <DisplayField label="Forma de pagamento" value="Débito" />
                  </Col>
                </Row>
              </div>
              {/* <Col className="text-right" xs="12">
                <Button color="primary" size="m" type="submit">
                  Salvar
                </Button>
              </Col> */}
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
}
