import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import YouTube from 'react-youtube';
import _ from 'lodash';
import moment from 'moment';

import useValidateField from '../../../../library/hooks/useValidateField';
import { validateAll } from '../../../../library/utils/validations';
import { showToast } from '../../../../store/toast';

import {
  ContentLoader,
  Input,
  RadioList,
  UploadInput,
  TextArea,
  CheckBox,
  SimplePopUp,
  ChooseTagsTable,
} from '../../../../components';

import {
  selectCourseDetails,
  selectCourseDetailsFetchState,
  getCourseDetails,
  updateCourseById,
  resetCourse,
  createCourse,
  deleteCourse,
} from '../../../../store/courses/courseDetails';
import navigationKeys from '../../../../library/enums/navigationKeys';
import useFomInitialData from '../../../../library/hooks/useFomInitialData';
// import formatDate from '../../../../library/utils/formatDate';

import SubCoursesTable from './SubCoursesTable';

// import styles from './course-detail.module.scss';

export default function CourseDetail({ match, history }) {
  const courseId = useMemo(() => match.params.courseId, [match.params]);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [
    confirmCourseRemovePopUpOpen,
    setconfirmCourseRemovePopUpOpen,
  ] = useState(false);

  const fetchState = useSelector(selectCourseDetailsFetchState);
  const course = useSelector(selectCourseDetails);

  const [courseStatus, setUserStatus] = useState(null);
  const [courseType, setCourseType] = useState(null);
  const [category, setCategory] = useState('');
  const [material, setMaterial] = useState(null);
  const [material2, setMaterial2] = useState(null);
  const [image, setImage] = useState(null);
  const [top50, setTop50] = useState(false);

  const [materialDefaultValue, setMaterialDefaultValue] = useState(null);
  const [material2DefaultValue, setMaterial2DefaultValue] = useState(null);
  const [imageDefaultValue, setImageDefaultValue] = useState(null);

  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    if (course) {
      course.get_image &&
        setImageDefaultValue({
          label: course.imageOriginalName,
          path: course.get_image,
        });
      course.get_material &&
        setMaterialDefaultValue({
          label: course.materialOriginalName,
          path: course.get_material,
        });
      course.get_material2 &&
        setMaterial2DefaultValue({
          label: course.material2OriginalName,
          path: course.get_material2,
        });

      if (course.category === 'Grupo') {
        setCategory('Grupo');
      } else if (course.category === 'Individual') {
        setCategory('Individual');
      }
      setUserStatus(course.status);
      setVideoId(course.video);
      setTop50(course.top50);
    }
  }, [course]);

  const getDate = useCallback((date, time) => {
    const momentTime = moment(time, 'HH:mm');

    return moment(date, 'DD/MM/YYYY')
      .hours(momentTime.hour())
      .minutes(momentTime.minutes())
      .format('YYYY-MM-DDTHH:mm:ss.SSS[z]');
  }, []);

  const initialData = useFomInitialData(
    {
      title: '',
      category: '',
      status: '',
      video: '',
      description: '',
    },
    course,
  );

  const validateField = useValidateField(formRef);

  useEffect(() => {
    if (courseId) {
      dispatch(getCourseDetails(courseId));
    }
    return () => {
      dispatch(resetCourse());
    };
  }, [courseId, dispatch]);

  const handleSubmit = useCallback(
    (form) => {
      if (courseStatus === null) {
        dispatch(
          showToast({
            type: 'danger',
            text: 'Status Obrigatório',
          }),
        );

        return;
      }

      if (category === '') {
        dispatch(
          showToast({
            type: 'danger',
            text: 'Categoria Obrigatório',
          }),
        );

        return;
      }

      if (!courseId) {
        const [formErrors, isValid] = validateAll(form);

        if (isValid) {
          const data = new FormData();

          data.append('title', form.title);
          // data.append('category', category);
          data.append('description', form.description);
          data.append('video', form.video);
          data.append('status', courseStatus);
          data.append('type', courseType);
          data.append('top50', top50);

          material && data.append('material', material, material.name);
          material2 && data.append('material2', material2, material2.name);
          image && data.append('image', image, image.name);

          dispatch(createCourse(data)).then((action) => {
            if (action.payload && action.payload.id) {
              history.push(
                `${navigationKeys.ADMIN_COURSES}/${action.payload.id}`,
              );
            }
          });
        }
        formRef.current.setErrors(formErrors);

        return;
      }

      const validForm = {};

      Object.keys(form).map((key) => {
        if (form[key]) {
          validForm[key] = form[key];
        }
      });

      const [formErrors, isValid] = validateAll(validForm);

      if (isValid) {
        if (Object.keys(validForm).length === 0) {
          dispatch(
            showToast({
              type: 'info',
              text: 'Nenhuma alteração encontrada',
            }),
          );

          return;
        }

        validForm.date = getDate(form.date, form.hour);

        delete validForm.time;

        const data = new FormData();

        data.append('title', form.title);
        data.append('description', form.description);
        category && data.append('category', category);
        // data.append('date', getDate(form.date, form.time));
        data.append('video', form.video);
        data.append('status', !!courseStatus);
        material && data.append('material', material, material.name);
        data.append('hasMaterial', !!material);
        material2 && data.append('material2', material2, material2.name);
        data.append('hasMaterial2', !!material2);
        image && data.append('image', image, image.name);
        data.append('hasImage', !!image);
        data.append('top50', top50);
        data.append('type', courseType);

        dispatch(updateCourseById({ id: course.id, formData: data }));
        return;
      }

      formRef.current.setErrors(formErrors);
    },
    [
      dispatch,
      course,
      formRef,
      courseId,
      history,
      courseStatus,
      material,
      image,
      category,
      getDate,
      material2,
      top50,
      courseType,
    ],
  );

  const handleVideoIdChange = useCallback(
    _.debounce((value) => setVideoId(value), 700),
    [],
  );
  if (fetchState.isLoading) {
    return <ContentLoader isLoading />;
  }

  return (
    <Container>
      <Col style={{ margin: '20px 0' }}>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {courseId ? 'Informações de Course' : 'Criação de Course'}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={handleSubmit}
              ref={formRef}
              initialData={initialData}
            >
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Título"
                      name="title"
                      className="form-control-alternative"
                      placeholder="Digite o nome..."
                    />
                  </Col>

                  <Col lg="6">
                    <RadioList
                      onValueChange={(value) => setCategory(value)}
                      value={category}
                      title="Tipo"
                      options={[
                        { label: 'Em grupo', value: 'Grupo' },
                        { label: 'Individual', value: 'Individual' },
                      ]}
                    />
                  </Col>

                  <Col lg="6">
                    <RadioList
                      onValueChange={(value) => setUserStatus(value)}
                      value={courseStatus}
                      title="Status"
                      options={[
                        { label: 'Ativo', value: true },
                        { label: 'Inativo', value: false },
                      ]}
                    />
                  </Col>

                  <Col lg="6">
                    <RadioList
                      onValueChange={(value) => setCourseType(value)}
                      value={courseType}
                      title="LIVE"
                      options={[
                        { label: 'LIVE', value: 'LIVE' },
                        { label: 'ONDEMAND', value: 'ONDEMAND' },
                      ]}
                    />
                  </Col>

                  <Col lg="6">
                    <UploadInput
                      accept="application/pdf"
                      label="Arquivo da Aula 1"
                      onChange={(file) => setMaterial(file)}
                      defaultValue={materialDefaultValue}
                    />
                  </Col>

                  <Col lg="6">
                    <UploadInput
                      accept="application/pdf"
                      label="Arquivo da Aula 2"
                      onChange={(file) => setMaterial2(file)}
                      defaultValue={material2DefaultValue}
                    />
                  </Col>

                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Vídeo"
                      name="video"
                      className="form-control-alternative"
                      placeholder="Digite o id do vídeo"
                      onChange={(e) => {
                        handleVideoIdChange(e.target.value);
                      }}
                    />
                  </Col>

                  <Col lg="6" style={{ margin: '20px 0' }}>
                    {videoId && (
                      <YouTube
                        videoId={videoId}
                        opts={{
                          height: '250',
                          width: '400',
                        }}
                      />
                    )}
                  </Col>

                  <Col lg="6">
                    <UploadInput
                      accept="image/*"
                      label="Imagem da Aula"
                      onChange={(file) => setImage(file)}
                      defaultValue={imageDefaultValue}
                    />
                  </Col>

                  <Col lg="6" style={{ marginTop: 0 }}>
                    <label className="form-control-label">Outras Opções</label>
                    <CheckBox
                      text="Top 50"
                      value={top50}
                      onClick={() => setTop50(!top50)}
                    />
                  </Col>

                  <Col lg="12">
                    <TextArea
                      rows={10}
                      validateField={validateField}
                      label="Descrição"
                      name="description"
                      className="form-control-alternative"
                      placeholder="Digite a descrição..."
                    />
                  </Col>
                </Row>
              </div>
              <Col className="text-right" xs="12">
                <Row className="justify-content-end">
                  {course && (
                    <Button
                      color="secondary"
                      size="m"
                      onClick={(e) => {
                        e.preventDefault();
                        setconfirmCourseRemovePopUpOpen(true);
                      }}
                    >
                      Deletar
                    </Button>
                  )}
                  <Button color="primary" size="m" type="submit">
                    Salvar
                  </Button>
                </Row>
              </Col>
            </Form>
          </CardBody>
        </Card>

        {course && <SubCoursesTable />}
        {course && <ChooseTagsTable id={course._id} type="course" />}
      </Col>

      {course && (
        <SimplePopUp
          isOpen={confirmCourseRemovePopUpOpen}
          onCancelClick={() => setconfirmCourseRemovePopUpOpen(false)}
          onConfirmClick={() =>
            dispatch(deleteCourse(course._id)).then((action) => {
              if (!action.error) {
                history.replace('/admin/courses');
                return;
              }

              setconfirmCourseRemovePopUpOpen(true);
            })
          }
        >
          <span>
            Tem certeza que deseja deletar o course{' '}
            <span style={{ fontWeight: 'bold' }}>{course.title}</span>?
          </span>
        </SimplePopUp>
      )}
    </Container>
  );
}
