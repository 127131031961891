import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { getDebateList } from './thunkActions';

const debateListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const debates = createSlice({
  name: 'debates/debateList',
  initialState: debateListAdapter.getInitialState(initialState),
  reducers: {
    resetDebatesList: (state) => {
      debateListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getDebateList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getDebateList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      debateListAdapter.setAll(state, action.payload.debates);
    },
    [getDebateList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
  },
});

export { getDebateList };

export const { resetDebatesList } = debates.actions;

export const debateListSelectors = debateListAdapter.getSelectors(
  (state) => state.debateList,
);

export const selectDebateListFetchState = (state) =>
  state.debateList.fetchState;

export default debates.reducer;
