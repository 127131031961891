import { useCallback } from 'react';

import { validateField } from '../utils/validations';

const useValidateField = (formRef) =>
  useCallback(
    (fieldName, value, previousErrorText) => {
      let _value = '';
      if (value || value === '') {
        _value = value;
      } else if (formRef.current) {
        _value = formRef.current.getFieldValue(fieldName);
      }

      const error = validateField(fieldName, _value, formRef);

      if (
        (error && (!previousErrorText || previousErrorText !== error)) ||
        previousErrorText
      ) {
        if (formRef.current) {
          formRef.current.setFieldError(fieldName, error);
        }
      } else if (!error) {
        if (formRef.current) {
          formRef.current.setFieldError(fieldName, null);
        }
      }
    },
    [formRef],
  );
export default useValidateField;
