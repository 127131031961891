import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, Col } from 'reactstrap';

import { forgotPassword } from '../../../store/loggedUser';
import Input from '../Login/Input';
import { validateAll } from '../../../library/utils/validations';
import useValidateField from '../../../library/hooks/useValidateField';

import styles from './forgot-password.module.scss';

export default function ForgotPasswordForm() {
  const dispatch = useDispatch();
  const [emailSent, setEmailSent] = useState(false);
  const formRef = useRef(null);

  const validateField = useValidateField(formRef);

  const handleSubmit = (form) => {
    const [formErrors, isValid] = validateAll(form);

    if (isValid) {
      dispatch(forgotPassword(form.email)).then(() => setEmailSent(true));
      return;
    }
    console.log(formErrors);

    formRef.current.setErrors(formErrors);
  };

  return (
    <div className={styles.container}>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {emailSent ? (
              <div>
                Um email foi enviado para{' '}
                {formRef.current.getFieldValue('email')}
              </div>
            ) : (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input
                  iconLeft="ni ni-email-83"
                  name="email"
                  placeholder="Email"
                  type="email"
                  autoComplete="new-email"
                  validateField={validateField}
                />
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="submit"
                    style={{
                      backgroundColor: '#ff810a',
                      border: 'none',
                    }}
                  >
                    Enviar
                  </Button>
                </div>
              </Form>
            )}
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}
