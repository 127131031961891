import React, {
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useDispatch } from 'react-redux';
import SimplePopUp from '../../../../components/SimplePopUp';
import { deleteTag } from '../../../../store/tags/tagsList';

const deleteModalInitialState = {
  open: false,
  tag: null,
};

// eslint-disable-next-line no-empty-pattern
const DeleteTagModal = ({}, ref) => {
  const dispatch = useDispatch();

  const [deleteModalState, setDeleteModalState] = useState(
    deleteModalInitialState,
  );

  useImperativeHandle(ref, () => ({
    open: (tag) => {
      setDeleteModalState({
        open: true,
        tag,
      });
    },
  }));

  const resetDeleteModal = useCallback(
    () => setDeleteModalState(deleteModalInitialState),
    [],
  );

  const handleDelete = useCallback(() => {
    setDeleteModalState({
      ...deleteModalState,
      open: false,
    });
    dispatch(deleteTag(deleteModalState.tag._id)).then((action) => {
      setDeleteModalState({
        ...deleteModalState,
        open: !action.payload,
      });
    });
  }, [deleteModalState, dispatch]);

  if (!deleteModalState.open) {
    return null;
  }

  return (
    <SimplePopUp
      isOpen
      onCancelClick={resetDeleteModal}
      onConfirmClick={handleDelete}
    >
      {' '}
      <span>
        Tem certeza que deseja deletar a tag{' '}
        <span style={{ fontWeight: 'bold' }}>{deleteModalState.tag.name}</span>?
      </span>
    </SimplePopUp>
  );
};

export default forwardRef(DeleteTagModal);
