import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import {
  getDebateList,
  debateListSelectors,
  selectDebateListFetchState,
} from '../../../../../store/debates/debateList';
import TableCard from '../../../../../components/TableCard';
import getStatusText from '../../../../../library/utils/getStatusText';
import useDebounce from '../../../../../library/hooks/useDebounce';
import formatDate from '../../../../../library/utils/formatDate';

import { Modal } from '../../../../../components';

import styles from '../styles.module.scss';

const SearchDebateModal = ({ onDebateChoosen, isOpen, onCancelPress }) => {
  const dispatch = useDispatch();
  const lsDebates = useSelector(debateListSelectors.selectAll);
  const fetchState = useSelector(selectDebateListFetchState);
  const [confirmDebateModalIsOpen, setConfirmDebateModalIsOpen] = useState(
    false,
  );
  const [debateChoosed, setDebateChoosed] = useState(null);

  const [search, setSearch] = useState('');

  useDebounce(
    () =>
      isOpen &&
      dispatch(
        getDebateList({ title: search, status: true, category: 'Grupo' }),
      ),
    [search, isOpen],
    {
      debounceAtStart: false,
    },
  );

  const goToPage = useCallback(
    (pageIndex) =>
      dispatch(
        getDebateList({
          title: search,
          status: true,
          category: 'Grupo',
          pageIndex,
        }),
      ),
    [dispatch, search],
  );

  return (
    <>
      <Modal isOpen={isOpen}>
        <div className={styles.searhStudentsContainer}>
          <TableCard
            contentLoaderStyle={{
              minWidth: 500,
            }}
            isLoading={fetchState.isLoading}
            page={fetchState.page}
            totalPages={fetchState.totalPages}
            onPageClick={goToPage}
            onNextPageClick={goToPage}
            onPreviusPageClick={goToPage}
            title="Lista de Alunos"
            headerComponent={
              <div className={styles.searchContainer}>
                <div>
                  <input
                    value={search}
                    className={styles.search}
                    placeholder="Buscar Aluno..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <Button
                  color="primary"
                  style={{ height: 40, marginTop: 6, marginRight: 8 }}
                  onClick={(e) => {
                    e.preventDefault();
                    onCancelPress && onCancelPress();
                  }}
                  size="sm"
                >
                  Cancelar
                </Button>
              </div>
            }
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Título</th>
                <th scope="col">Data</th>
                <th scope="col">Categoria</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {lsDebates.map((debate) => (
                <tr
                  key={debate._id}
                  className={styles.tableLine}
                  onClick={() => {
                    setDebateChoosed(debate);
                    setConfirmDebateModalIsOpen(true);
                  }}
                >
                  <td>{debate.title}</td>
                  <td>{formatDate(debate.date)}</td>
                  <td>{debate.category}</td>
                  <td>{getStatusText(debate.status)}</td>
                </tr>
              ))}
            </tbody>
          </TableCard>
        </div>
        <Modal isOpen={confirmDebateModalIsOpen}>
          <div
            style={{
              width: 500,
              height: 200,
              background: '#fafafa',
              fontWeight: '500',
              fontSize: 22,
              padding: 20,
              borderRadius: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <span style={{ flex: 1 }}>
              Tem certeza que deseja adcionar o debate{' '}
              <span style={{ fontWeight: 'bold' }}>
                {debateChoosed && debateChoosed.title}
              </span>{' '}
              a esta turma?
            </span>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                color="secondary"
                style={{ height: 40, marginTop: 6, marginRight: 8 }}
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmDebateModalIsOpen(false);
                  setDebateChoosed(null);
                }}
                size="sm"
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                style={{ height: 40, marginTop: 6, marginRight: 8 }}
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmDebateModalIsOpen(false);
                  onDebateChoosen && onDebateChoosen(debateChoosed);
                }}
                size="sm"
              >
                Adcionar
              </Button>
            </div>
          </div>
        </Modal>
      </Modal>
    </>
  );
};

export default SearchDebateModal;
