import { useEffect, useRef } from 'react';

function useDebounce(callback, dependencyList, options) {
  const debounceAtStart =
    options && typeof options.debounceAtStart === 'boolean'
      ? options.debounceAtStart
      : true;

  const dontDebounce = useRef(!debounceAtStart);

  const debounceTime =
    options && typeof options.debounceTime === 'number'
      ? options.debounceTime
      : 700;

  return useEffect(() => {
    let timeoutId = '';

    if (dontDebounce.current) {
      dontDebounce.current = false;
      callback();
    } else {
      timeoutId = setTimeout(() => callback(), debounceTime);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [...dependencyList, debounceTime]);
}

export default useDebounce;
