import React from 'react';

export default function Select({
  label = '',
  options = [],
  onChangeValue,
  value,
}) {
  return (
    <>
      <p>{label}</p>

      <select
        name="cars"
        id="cars"
        style={{
          border: 'none',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.075)',
          padding: 5,
          borderRadius: 3,
        }}
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
      >
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </>
  );
}
