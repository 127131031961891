import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';

import useValidateField from '../../../../library/hooks/useValidateField';
import { validateAll } from '../../../../library/utils/validations';
import { showToast } from '../../../../store/toast/index';
import {
  ContentLoader,
  Input,
  RadioList,
  SimplePopUp,
} from '../../../../components';
import {
  selectMediatorDetails,
  selectMediatorDetailsFetchState,
  getMediatorDetails,
  updateMediatorById,
  resetMediator,
  createMediator,
} from '../../../../store/mediators/mediatorDetails';
import navigationKeys from '../../../../library/enums/navigationKeys';
import useFomInitialData from '../../../../library/hooks/useFomInitialData';
import { deleteMediator } from '../../../../store/mediators/mediatorDetails/thunkActions';

export default function MediatorDetail({ match, history }) {
  const userId = useMemo(() => match.params.userId, [match.params]);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [
    confirmMediatorRemovePopUpOpen,
    setConfirmMediatorRemovePopUpOpen,
  ] = useState(false);

  const fetchState = useSelector(selectMediatorDetailsFetchState);
  const mediator = useSelector(selectMediatorDetails);

  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    if (mediator) {
      setUserStatus(mediator.status);
    }
  }, [mediator]);

  const initialData = useFomInitialData(
    {
      name: '',
      email: '',
      surname: '',
      phone: '',
    },
    mediator,
  );

  const validateField = useValidateField(formRef);

  useEffect(() => {
    if (userId) {
      dispatch(getMediatorDetails(userId));
    }
    return () => {
      dispatch(resetMediator());
    };
  }, [userId, dispatch]);

  const handleSubmit = useCallback(
    (form) => {
      if (userStatus === null) {
        dispatch(
          showToast({
            type: 'danger',
            text: 'Status Obrigatório',
          }),
        );

        return;
      }

      const [formErrors, isValid] = validateAll(form);

      if (!isValid) {
        formRef.current.setErrors(formErrors);
        return;
      }

      if (!userId) {
        const newMediator = {
          name: form.name,
          phone: form.phone,
          surname: form.surname,
          password: form.newPassword,
          email: form.email,
          status: userStatus,
        };

        dispatch(createMediator(newMediator)).then((action) => {
          if (action.payload && action.payload.id) {
            history.push(
              `${navigationKeys.ADMIN_MEDIATORS}/${action.payload.id}`,
            );
          }
        });

        return;
      }

      const mediatorUpdated = {
        id: mediator.id,
        name: form.name,
        phone: form.phone,
        surname: form.surname,
        email: form.email,
        status: userStatus,
      };

      dispatch(updateMediatorById(mediatorUpdated));
    },
    [dispatch, mediator, formRef, userId, history, userStatus],
  );

  if (fetchState.isLoading) {
    return <ContentLoader isLoading />;
  }

  return (
    <Container>
      <Col style={{ margin: '20px 0' }}>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Informações de Mediador</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={handleSubmit}
              ref={formRef}
              initialData={initialData}
            >
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Nome"
                      name="name"
                      className="form-control-alternative"
                      id="input-username"
                      placeholder="Digite o nome..."
                      type="text"
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Sobrenome"
                      name="surname"
                      className="form-control-alternative"
                      id="input-username"
                      placeholder="Digite o sobrenome..."
                      type="text"
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      mask="(99) 99999-9999"
                      label="Telefone"
                      name="phone"
                      className="form-control-alternative"
                      id="input-username"
                      placeholder="Digite o nome..."
                      type="phone"
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      validateField={validateField}
                      label="Email"
                      name="email"
                      className="form-control-alternative"
                      id="input-email"
                      placeholder="Digite o email"
                      type="email"
                    />
                  </Col>
                  {!userId && (
                    <>
                      <Col lg="6">
                        <Input
                          validateField={validateField}
                          label="Senha"
                          name="newPassword"
                          className="form-control-alternative"
                          placeholder="Digite a senha"
                          type="password"
                          autoComplete="new-password"
                        />
                      </Col>
                      <Col lg="6">
                        <Input
                          validateField={validateField}
                          label="Confirmar Senha"
                          name="confirmNewPassword"
                          className="form-control-alternative"
                          placeholder="Confirme a senha"
                          type="password"
                          autoComplete="new-password"
                        />
                      </Col>
                    </>
                  )}
                  <Col lg="6">
                    <RadioList
                      onValueChange={(value) => setUserStatus(value)}
                      value={userStatus}
                      title="Status"
                      options={[
                        { label: 'Ativo', value: true },
                        { label: 'Inativo', value: false },
                      ]}
                    />
                  </Col>
                </Row>
              </div>
              <Col className="text-right" xs="12">
                <Row className="justify-content-end">
                  {mediator && (
                    <Button
                      color="secondary"
                      size="m"
                      onClick={() => setConfirmMediatorRemovePopUpOpen(true)}
                    >
                      Deletar
                    </Button>
                  )}
                  <Button color="primary" size="m" type="submit">
                    Salvar
                  </Button>
                </Row>
              </Col>
            </Form>
          </CardBody>
        </Card>
      </Col>

      {mediator && (
        <SimplePopUp
          isOpen={confirmMediatorRemovePopUpOpen}
          onCancelClick={() => setConfirmMediatorRemovePopUpOpen(false)}
          onConfirmClick={() =>
            dispatch(deleteMediator(mediator._id)).then((action) => {
              if (!action.error) {
                history.replace('/admin/mediators');
                return;
              }

              setConfirmMediatorRemovePopUpOpen(true);
            })
          }
        >
          <span>
            Tem certeza que deseja deletar o Mediador{' '}
            <span style={{ fontWeight: 'bold' }}>{mediator.name}</span>?
          </span>
        </SimplePopUp>
      )}
    </Container>
  );
}
