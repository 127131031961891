import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import {
  getTagList,
  tagListSelectors,
  selectTagsListFetchState,
} from '../../store/tags/tagsList';
import TableCard from '../TableCard';
import useDebounce from '../../library/hooks/useDebounce';
import DisplayStatus from '../DisplayStatus';

import Modal from '../Modal';

import styles from './styles.module.scss';

const SearchTagModal = ({ onTagChosen, isOpen, onCancelPress, type }) => {
  const dispatch = useDispatch();
  const lsTags = useSelector(tagListSelectors.selectAll);
  const fetchState = useSelector(selectTagsListFetchState);
  const tagType = useMemo(
    () =>
      type === 'course'
        ? { label: 'Curso', type: 'course' }
        : { label: 'Debate', type: 'debate' },
    [type],
  );

  const [search, setSearch] = useState('');

  useDebounce(
    () => isOpen && dispatch(getTagList({ title: search, type: tagType.type })),
    [search, isOpen],
    {
      debounceAtStart: false,
    },
  );

  const goToPage = useCallback(
    (pageIndex) =>
      dispatch(
        getTagList({
          title: search,
          pageIndex,
          type: tagType.type,
        }),
      ),
    [dispatch, search, tagType.type],
  );

  return (
    <Modal isOpen={isOpen}>
      <div className={styles.searhStudentsContainer}>
        <TableCard
          contentLoaderStyle={{
            minWidth: 500,
          }}
          isLoading={fetchState.isLoading}
          page={fetchState.page}
          totalPages={fetchState.totalPages}
          onPageClick={goToPage}
          onNextPageClick={goToPage}
          onPreviusPageClick={goToPage}
          title={`Lista de Tags de ${tagType.label}`}
          headerComponent={
            <div className={styles.searchContainer}>
              <div>
                <input
                  value={search}
                  className={styles.search}
                  placeholder={`Buscar Tag ${tagType.label}...`}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Button
                color="primary"
                style={{ height: 40, marginTop: 6, marginRight: 8 }}
                onClick={(e) => {
                  e.preventDefault();
                  onCancelPress && onCancelPress();
                }}
                size="sm"
              >
                Cancelar
              </Button>
            </div>
          }
        >
          <thead className="thead-light">
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {lsTags.map((tag) => (
              <tr
                key={tag._id}
                className={styles.tableLine}
                onClick={() => {
                  onTagChosen && onTagChosen(tag);
                }}
              >
                <td>{tag.name}</td>
                <td>
                  <DisplayStatus status={tag.status} />
                </td>
              </tr>
            ))}
          </tbody>
        </TableCard>
      </div>
    </Modal>
  );
};

export default SearchTagModal;
