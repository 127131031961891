import { createAsyncThunk } from '@reduxjs/toolkit';

import FormsService from '../../../services/FormsService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';
import { showToast } from '../../toast';

import { showLoader, hideLoader } from '../../loader';

export const getFormsList = createAsyncThunk(
  'forms/getFormsList',
  async (params, { dispatch }) => {
    const _params = {};

    const page = (params && params.pageIndex) || 1;

    _params.limit = (params && params.limit) || 10;
    _params.skip = (page - 1) * _params.limit;

    if (params.type) _params.type = params.type;

    try {
      const response = await FormsService.getFormList(_params);

      return {
        ...response.data,
        page,
        limit: _params.limit,
      };
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const deleteForm = createAsyncThunk(
  'formDetails/deleteForm',
  async (formId, { dispatch }) => {
    try {
      dispatch(showLoader());
      await FormsService.deleteForm(formId);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Form deletado com sucesso!',
        }),
      );
      return formId;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
