/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaTimes, FaEye } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { Modal, TableCard } from '../../../../../components';
import navigationKeys from '../../../../../library/enums/navigationKeys';
import {
  selectClassroomDetails,
  AddStundentToClassroom,
  removeStundentToClassroom,
} from '../../../../../store/classrooms/classroomDetails';

import getStatusText from '../../../../../library/utils/getStatusText';

import styles from '../styles.module.scss';

import SearchStudentModal from './SearchStudentModal';

const StudentsOfClassroomTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchStudentsModalIsOpen, setSearchStudentsModalIsOpen] = useState(
    false,
  );

  const classRoomDetails = useSelector(selectClassroomDetails);
  const lsStudents = useMemo(
    () => (classRoomDetails ? classRoomDetails.students : []),
    [classRoomDetails],
  );

  const [studentMarkedToRemove, setStudentMarkedToRemove] = useState(null);

  return (
    <>
      <div className={styles.tableContainer}>
        <TableCard
          title="Lista de Alunos"
          showHeaderButton
          headerButtonClick={() => setSearchStudentsModalIsOpen(true)}
          headerButtonText="Adcionar Novo Aluno"
        >
          <thead className="thead-light">
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Email</th>
              <th scope="col">Telefone</th>
              <th scope="col">Status</th>
              <th scope="col">Matrícula</th>
              <th scope="col">Ver?</th>
              <th scope="col">Remover?</th>
            </tr>
          </thead>
          <tbody>
            {lsStudents.map((user) => (
              <tr key={user._id} className={styles.tableLineStudents}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{getStatusText(user.status)}</td>
                <td>{user.enrollment}</td>
                <td>
                  <FaEye
                    size={18}
                    onClick={() =>
                      history.push(
                        `${navigationKeys.ADMIN_STUDENTS}/${user._id}`,
                      )
                    }
                  />
                </td>
                <td>
                  <FaTimes
                    size={18}
                    onClick={() => setStudentMarkedToRemove(user)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </TableCard>
      </div>
      <SearchStudentModal
        onStudentClick={(student) => {
          dispatch(
            AddStundentToClassroom({
              stundentId: student._id,
              classroomId: classRoomDetails._id,
            }),
          ).then(() => setSearchStudentsModalIsOpen(false));
        }}
        isOpen={searchStudentsModalIsOpen}
        onCancelPress={() => setSearchStudentsModalIsOpen(false)}
      />
      <Modal isOpen={studentMarkedToRemove !== null}>
        <div
          style={{
            width: 500,
            height: 200,
            background: '#fafafa',
            fontWeight: '500',
            fontSize: 22,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <span style={{ flex: 1 }}>
            Tem certeza que deseja remover o Aluno{' '}
            <span style={{ fontWeight: 'bold' }}>
              {studentMarkedToRemove && studentMarkedToRemove.name}
            </span>{' '}
            desta turma?
          </span>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              color="secondary"
              style={{ height: 40, marginTop: 6, marginRight: 8 }}
              onClick={(e) => {
                e.preventDefault();
                setStudentMarkedToRemove(null);
              }}
              size="sm"
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              style={{ height: 40, marginTop: 6, marginRight: 8 }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(
                  removeStundentToClassroom({
                    stundentId: studentMarkedToRemove._id,
                    classroomId: classRoomDetails._id,
                  }),
                ).then(() => setStudentMarkedToRemove(null));
              }}
              size="sm"
            >
              Remover
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StudentsOfClassroomTable;
