import React from 'react';
import { Link } from 'react-router-dom';

import { Navbar, Container } from 'reactstrap';

import Dropdown from './Dropdown';
import styles from './navbar.module.scss';

const AdminNavbar = ({ title = '' }) => {
  return (
    <>
      <Navbar className={styles.header}>
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {title}
          </Link>
          {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
  </Form> */}
          <Dropdown />
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
