import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { Button, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import useValidateField from '../../../../library/hooks/useValidateField';
import { validateAll } from '../../../../library/utils/validations';
import { showToast } from '../../../../store/toast/index';
import {
  Input,
  DateTimeInput,
  RadioList,
  Select,
} from '../../../../components';
import {
  selectClassroomDetails,
  updateClassroomById,
  createClassroom,
} from '../../../../store/classrooms/classroomDetails';
import navigationKeys from '../../../../library/enums/navigationKeys';
import useFomInitialData from '../../../../library/hooks/useFomInitialData';

import styles from './styles.module.scss';
import daysOfWeek from '../utils/daysOfWeek';

export default function ClassroomDetail() {
  const { classroomId } = useParams();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const history = useHistory();

  const classroom = useSelector(selectClassroomDetails);

  const [classroomStatus, setClassroomStatus] = useState(null);
  const [dayOfWeek, setDayOfWeek] = useState(0);

  useEffect(() => {
    if (classroom) {
      setDayOfWeek(classroom.dayOfWeek);
      setClassroomStatus(classroom.status);
    }
  }, [classroom]);

  const hourAndMinuteFormated = useMemo(() => {
    if (classroom) {
      console.log('hourAndMinuteFormated', classroom);
    }

    if (
      !classroom ||
      typeof classroom.hour !== 'number' ||
      typeof classroom.minute !== 'number'
    ) {
      return '';
    }

    return moment()
      .hour(classroom.hour)
      .minute(classroom.minute)
      .format('HH:mm');
  }, [classroom]);

  const initialData = useFomInitialData(
    {
      name: '',
      dayOfWeek: '',
      time: '',
    },
    classroom
      ? {
          ...classroom,
          time: hourAndMinuteFormated,
        }
      : {},
  );

  const validateField = useValidateField(formRef);

  const handleSubmit = useCallback(
    (form) => {
      if (classroomStatus === null) {
        dispatch(
          showToast({
            type: 'danger',
            text: 'Status Obrigatório',
          }),
        );

        return;
      }

      const [formErrors, isValid] = validateAll(form);

      const timeArray = form.time.split(':');
      const newClassroom = {
        name: form.name,
        dayOfWeek,
        status: classroomStatus,
        hour: Number(timeArray[0]),
        minute: Number(timeArray[1]),
      };

      if (isValid) {
        if (!classroomId) {
          dispatch(createClassroom(newClassroom)).then((action) => {
            if (action.payload && action.payload.id) {
              history.push(
                `${navigationKeys.ADMIN_CLASSROOMS}/${action.payload.id}`,
              );
            }
          });

          return;
        }

        dispatch(
          updateClassroomById({
            id: classroom.id,
            ...newClassroom,
          }),
        );
        return;
      }

      formRef.current.setErrors(formErrors);
    },
    [
      dispatch,
      classroom,
      formRef,
      classroomId,
      history,
      classroomStatus,
      dayOfWeek,
    ],
  );

  return (
    <div className={styles.tableContainer}>
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">
                {classroomId ? 'Informações de Turma' : 'Criação de Turma'}
              </h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit} ref={formRef} initialData={initialData}>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <Input
                    validateField={validateField}
                    label="Nome"
                    name="name"
                    className="form-control-alternative"
                    placeholder="Digite o nome..."
                  />
                </Col>

                <Col lg="6">
                  <Select
                    label="Dia da Semana"
                    onChangeValue={(value) => setDayOfWeek(value)}
                    value={dayOfWeek}
                    options={daysOfWeek}
                  />
                  {/* (<Input
                    validateField={validateField}
                    label="Dia"
                    name="dayOfWeek"
                    className="form-control-alternative"
                    placeholder="Digite o nome..."
                  /> */}
                </Col>

                <Col lg="6">
                  <DateTimeInput
                    label="Hora"
                    name="time"
                    viewMode="time"
                    dateFormat={false}
                    timeFormat="HH:mm"
                    placeholder="Selecione a hora..."
                  />
                </Col>

                <Col lg="6">
                  <RadioList
                    onValueChange={(value) => setClassroomStatus(value)}
                    value={classroomStatus}
                    title="Status"
                    options={[
                      { label: 'Ativo', value: true },
                      { label: 'Inativo', value: false },
                    ]}
                  />
                </Col>
              </Row>
            </div>
            <Col className="text-right" xs="12">
              <Button color="primary" size="m" type="submit">
                Salvar
              </Button>
            </Col>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
