import { createSlice, createSelector } from '@reduxjs/toolkit';

import {
  logUserIn,
  forgotPassword,
  resetPassword,
  logOutUser,
} from './thunkActions';

const loggedUser = createSlice({
  name: 'account/user',
  initialState: null,
  reducers: {
    setLoggedUser: (state, action) => {
      return action.payload;
    },
  },
  extraReducers: {
    [logUserIn.fulfilled]: (state, action) => {
      return action.payload;
    },
    [logOutUser.fulfilled]: () => {
      return null;
    },
  },
});

const { setLoggedUser } = loggedUser.actions;

const selectLoggedUser = (state) => state.loggedUser;
const selectIsUserLogged = createSelector(
  selectLoggedUser,
  (_loggedUser) => !!_loggedUser,
);

export default loggedUser.reducer;
export {
  logOutUser,
  setLoggedUser,
  selectLoggedUser,
  logUserIn,
  selectIsUserLogged,
  forgotPassword,
  resetPassword,
};
