import { createAsyncThunk } from '@reduxjs/toolkit';
import { showToast } from '../../toast';
import { showLoader, hideLoader } from '../../loader';
import DebatesService from '../../../services/DebatesService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';

export const getDebateDetails = createAsyncThunk(
  'debateDetails/getDebateDetails',
  async (debateId, { dispatch }) => {
    try {
      const response = await DebatesService.getDebateById(debateId);

      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const updateDebateById = createAsyncThunk(
  'debateDetails/updateDebateById',
  async ({ id, formData }, { dispatch }) => {
    try {
      const response = await DebatesService.updateDebateById(id, formData);

      dispatch(
        showToast({
          type: 'success',
          text: 'Debate Atualizado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createDebate = createAsyncThunk(
  'debateDetails/createDebate',
  async (debate, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await DebatesService.createDebate(debate);

      dispatch(
        showToast({
          type: 'success',
          text: 'Debate Criado com sucesso!',
        }),
      );

      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const deleteDebate = createAsyncThunk(
  'debateDetails/deleteDebate',
  async (debateId, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await DebatesService.deleteDebate(debateId);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Debate deletado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
