import React from 'react';
import { Provider } from 'react-redux';

import Routes from './navigation/routes';
import store from './store';

import './App.css';
import Loading from './components/Loading';
import ToastContainer from './components/ToastContainer';

function App() {
  return (
    <Provider store={store}>
      <Loading />
      <ToastContainer />
      <Routes />
    </Provider>
  );
}

export default App;
