import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FaUserCog,
  FaFileSignature,
  FaUserFriends,
  FaChalkboardTeacher,
  FaUserGraduate,
  FaBook,
  FaUsers,
  FaNewspaper,
  FaClipboard,
} from 'react-icons/fa';

// reactstrap components
import { Navbar, Nav, Container } from 'reactstrap';

import styles from './sidebar.module.scss';
import navigationKeys from '../../library/enums/navigationKeys';

const NavButton = ({ onClick, label = '', active = false, IconComponent }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${styles.button} ${active ? styles.activeButton : ''}`}
    >
      <div>
        {IconComponent}
        <span style={{ marginLeft: 15 }}>{label}</span>
      </div>
    </button>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();

  const subRoutePath = location.pathname.split('/')[2];

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Collapse header */}
        <Nav className="mb-md-3" navbar>
          <NavButton
            active={subRoutePath === 'admins'}
            onClick={() => history.push('/admin/admins')}
            IconComponent={<FaUserCog size={22} />}
            label="Admins"
          />
          <NavButton
            active={subRoutePath === 'subscriptions'}
            onClick={() => history.push('/admin/subscriptions')}
            IconComponent={<FaFileSignature size={22} />}
            label="Assinaturas"
          />
          {/* <NavButton
            active={subRoutePath === 'home'}
            onClick={() => history.push('/admin/home')}
            iconClassName="ni ni-spaceship"
            label="Início"
            IconComponent={<FaHome size={22} />}
          /> */}
          <NavButton
            active={subRoutePath === 'mediators'}
            onClick={() => history.push(navigationKeys.ADMIN_MEDIATORS)}
            label="Mediadores"
            IconComponent={<FaUserFriends size={22} />}
          />
          <NavButton
            active={subRoutePath === 'professors'}
            onClick={() => history.push(navigationKeys.ADMIN_PROFESSORS)}
            label="Professores"
            IconComponent={<FaChalkboardTeacher size={22} />}
          />
          <NavButton
            active={subRoutePath === 'students'}
            onClick={() => history.push(navigationKeys.ADMIN_STUDENTS)}
            label="Alunos"
            IconComponent={<FaUserGraduate size={22} />}
          />
          <NavButton
            active={subRoutePath === 'debates'}
            onClick={() => history.push(navigationKeys.ADMIN_DEBATES)}
            label="Debates"
            IconComponent={<FaBook size={22} />}
          />
          <NavButton
            active={subRoutePath === 'debate-tags'}
            onClick={() => history.push(navigationKeys.ADMIN_DEBATES_TAGS)}
            label="Tags de debates"
            IconComponent={<FaBook size={22} />}
          />
          <NavButton
            active={subRoutePath === 'classrooms'}
            onClick={() => history.push(navigationKeys.ADMIN_CLASSROOMS)}
            label="Turmas"
            IconComponent={<FaUsers size={22} />}
          />
          <NavButton
            active={subRoutePath === 'newsletters'}
            onClick={() => history.push(navigationKeys.ADMIN_NEWSLETTERS)}
            label="Newsletters"
            IconComponent={<FaNewspaper size={22} />}
          />
          <NavButton
            active={subRoutePath === 'courses'}
            onClick={() => history.push(navigationKeys.ADMIN_COURSES)}
            label="Cursos"
            IconComponent={<FaNewspaper size={22} />}
          />
          <NavButton
            active={subRoutePath === 'course-tags'}
            onClick={() => history.push(navigationKeys.ADMIN_COURSES_TAGS)}
            label="Tags de Cursos"
            IconComponent={<FaBook size={22} />}
          />
          <NavButton
            active={subRoutePath === 'forms'}
            onClick={() => history.push(navigationKeys.ADMIN_FORMS)}
            label="Forms"
            IconComponent={<FaClipboard size={22} />}
          />
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Sidebar;
