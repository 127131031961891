export default [
  {
    label: 'Domingo',
    value: 0,
  },
  {
    label: 'Segunda',
    value: 1,
  },
  {
    label: 'Terça',
    value: 2,
  },
  {
    label: 'Quarta',
    value: 3,
  },
  {
    label: 'Quinta',
    value: 4,
  },
  {
    label: 'Sexta',
    value: 5,
  },
  {
    label: 'Sábado',
    value: 6,
  },
];
