import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';

import {
  getFormsList,
  formsListSelectors,
  selectFormsListFetchState,
  resetFormsList,
  deleteForm,
} from '../../../../store/forms/formsList';
import {
  TableCard,
  DisplayStatus,
  SimplePopUp,
  DisplayField,
  Modal,
} from '../../../../components';

import styles from './styles.module.scss';
import useDebounce from '../../../../library/hooks/useDebounce';
import useFormatDate from '../../../../library/hooks/useFomatDate';

const FormsTable = () => {
  const dispatch = useDispatch();
  const lsForm = useSelector(formsListSelectors.selectAll);
  const fetchState = useSelector(selectFormsListFetchState);

  const [formSelected, setFormSelected] = useState(null);
  const [deleteFormModalIsOpen, setDeleteFormModalIsOpen] = useState(false);

  const [search, setSearch] = useState('');

  const handleDelete = useCallback(() => {
    dispatch(deleteForm(formSelected._id));
    setFormSelected(null);
    setDeleteFormModalIsOpen(null);
  }, [dispatch, formSelected]);

  useDebounce(() => dispatch(getFormsList({ type: search })), [search], {
    debounceAtStart: false,
  });

  const goToPage = useCallback(
    (pageIndex) => dispatch(getFormsList({ pageIndex, type: search })),
    [dispatch, search],
  );

  const formatDate = useFormatDate();

  useEffect(() => {
    return () => {
      dispatch(resetFormsList());
    };
  }, [dispatch]);

  return (
    <>
      <div className={styles.backgroundTop} />
      <div className={styles.container}>
        <div className="col">
          <div className="row align-items-center">
            <div>
              <input
                value={search}
                className={styles.search}
                placeholder="Buscar por Tipo..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <TableCard
            isLoading={fetchState.isLoading}
            page={fetchState.page}
            totalPages={fetchState.totalPages}
            onPageClick={goToPage}
            onNextPageClick={goToPage}
            onPreviusPageClick={goToPage}
            title="Lista de Forms"
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Email</th>
                <th scope="col">Tipo</th>
                <th scope="col">Criado Em</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {lsForm.map((form) => (
                <tr
                  key={form._id}
                  className={styles.tableLine}
                  onClick={() => setFormSelected(form)}
                >
                  <td>{form.email}</td>
                  <td>{form.type}</td>
                  <th scope="col">{formatDate(form.createdAt)}</th>
                  <td>
                    <DisplayStatus status={form.status} />
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCard>
        </div>
      </div>

      {!!formSelected && (
        <Modal isOpen>
          <div
            style={{
              backgroundColor: '#FFF',
              width: 800,
              padding: 15,
              borderRadius: 10,
            }}
          >
            <Row>
              {formSelected.name && (
                <Col lg="6">
                  <DisplayField label="Nome" value={formSelected.name} />
                </Col>
              )}

              {formSelected.email && (
                <Col lg="6">
                  <DisplayField label="Email" value={formSelected.email} />
                </Col>
              )}

              {formSelected.whatsapp && (
                <Col lg="6">
                  <DisplayField
                    label="Whatsapp"
                    value={formSelected.whatsapp}
                  />
                </Col>
              )}

              {formSelected.mattersOfInterest && (
                <Col lg="6">
                  <DisplayField
                    label="Assuntos De Interesse"
                    value={formSelected.mattersOfInterest}
                  />
                </Col>
              )}

              {formSelected.englishLevel && (
                <Col lg="6">
                  <DisplayField
                    label="Nível de Inglês"
                    value={formSelected.englishLevel}
                  />
                </Col>
              )}

              {formSelected.message && (
                <Col lg="6">
                  <DisplayField label="Mensagem" value={formSelected.message} />
                </Col>
              )}

              {formSelected.educationalInstitution && (
                <Col lg="6">
                  <DisplayField
                    label="Instituição Educacional"
                    value={formSelected.educationalInstitution}
                  />
                </Col>
              )}

              {formSelected.responsibility && (
                <Col lg="6">
                  <DisplayField
                    label="Responsabilidade"
                    value={formSelected.responsibility}
                  />
                </Col>
              )}

              {formSelected.phone && (
                <Col lg="6">
                  <DisplayField label="Telefone" value={formSelected.phone} />
                </Col>
              )}

              {formSelected.type && (
                <Col lg="6">
                  <DisplayField label="Tipo" value={formSelected.type} />
                </Col>
              )}

              <Col className="text-right" xs="12">
                <Row className="justify-content-end mr-1">
                  <Button
                    color="secondary"
                    size="m"
                    onClick={() => setFormSelected(null)}
                  >
                    Cancelar
                  </Button>

                  <Button
                    color="primary"
                    size="m"
                    onClick={() => setDeleteFormModalIsOpen(true)}
                  >
                    Deletar
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
        </Modal>
      )}

      {!!formSelected && !!deleteFormModalIsOpen && (
        <SimplePopUp
          isOpen
          onCancelClick={() => setDeleteFormModalIsOpen(false)}
          onConfirmClick={handleDelete}
        >
          {' '}
          <span>
            Tem certeza que deseja deletar a form{' '}
            <span style={{ fontWeight: 'bold' }}>{formSelected.email}</span>?
          </span>
        </SimplePopUp>
      )}
    </>
  );
};

export default FormsTable;
