import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from '../../toast';
import TagsService from '../../../services/TagsService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';
import { showLoader, hideLoader } from '../../loader';

export const getTagList = createAsyncThunk(
  'userList/getTagList',
  async (params, { dispatch }) => {
    const _params = {};

    const page = (params && params.pageIndex) || 1;

    _params.limit = (params && params.limit) || 10;
    _params.skip = (page - 1) * _params.limit;

    _params.type = params.type;

    if (params && params.name) {
      _params.name = params.name;
    }

    try {
      const response = await TagsService.getTagList(_params);

      return {
        ...response.data,
        page,
        limit: _params.limit,
      };
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const deleteTag = createAsyncThunk(
  'tagDetails/deleteTag',
  async (tagId, { dispatch }) => {
    try {
      dispatch(showLoader());
      await TagsService.deleteTag(tagId);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Tag deletada com sucesso!',
        }),
      );
      return tagId;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createTag = createAsyncThunk(
  'tagDetails/createTag',
  async (tag, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await TagsService.createTag(tag);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Tag Criado com sucesso!',
        }),
      );

      dispatch(getTagList({ name: '' }));

      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const updateTagById = createAsyncThunk(
  'tagDetails/updateTagById',
  async ({ id, ...rest }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await TagsService.updateTagById(id, rest);

      dispatch(hideLoader());

      dispatch(
        showToast({
          type: 'success',
          text: 'Tag Atualizado com sucesso!',
        }),
      );

      dispatch(getTagList({ name: '' }));
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
