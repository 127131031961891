import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { getProfessorList } from './thunkActions';

const professorListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const professors = createSlice({
  name: 'root/professorList',
  initialState: professorListAdapter.getInitialState(initialState),
  reducers: {
    resetProfessorsList: (state) => {
      professorListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getProfessorList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getProfessorList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      professorListAdapter.setAll(state, action.payload.teachers);
    },
    [getProfessorList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
  },
});

export { getProfessorList };
export const { resetProfessorsList } = professors.actions;

export const professorListSelectors = professorListAdapter.getSelectors(
  (state) => state.professorList,
);

export const selectProfessorListFetchState = (state) =>
  state.professorList.fetchState;

export default professors.reducer;
