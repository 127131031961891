import { createAsyncThunk } from '@reduxjs/toolkit';
import { showToast } from '../../toast';
import { showLoader, hideLoader } from '../../loader';
import ClassroomsService from '../../../services/ClassroomsService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';

export const getClassroomDetails = createAsyncThunk(
  'classroomsDetails/getClassroomDetails',
  async (classroomsId, { dispatch }) => {
    try {
      const response = await ClassroomsService.getClassroomById(classroomsId);

      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const updateClassroomById = createAsyncThunk(
  'classroomDetails/updateClassroomById',
  async ({ id, ...rest }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await ClassroomsService.updateClassroomById(id, rest);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Turma Atualizada com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createClassroom = createAsyncThunk(
  'classroomDetails/createClassroom',
  async (classroom, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await ClassroomsService.createClassroom(classroom);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Turma Criada com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const AddStundentToClassroom = createAsyncThunk(
  'classroomDetails/AddStundentToClassroom',
  async ({ stundentId, classroomId }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await ClassroomsService.addStudentToClassroom(
        classroomId,
        stundentId,
      );

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Aluno Adcionado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const removeStundentToClassroom = createAsyncThunk(
  'classroomDetails/AddStundentToClassroom',
  async ({ stundentId, classroomId }, { dispatch, getState }) => {
    try {
      dispatch(showLoader());
      await ClassroomsService.removeStudentFromClassroom(
        classroomId,
        stundentId,
      );

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Aluno Removido com sucesso!',
        }),
      );

      const classroomDetails = getState().classroomDetails.data;

      const newClassroomDetails = {
        ...classroomDetails,
        students: [...classroomDetails.students],
      };

      if (classroomDetails && classroomDetails.students) {
        const index = classroomDetails.students.findIndex(
          (item) => item.id === stundentId,
        );

        const students = [...classroomDetails.students];

        students.splice(index, 1);

        newClassroomDetails.students = students;
      }

      return newClassroomDetails;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
