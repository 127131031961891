import Api from '../library/api';

class NewslettersService {
  getNewsletterList = (params) =>
    Api.get('/newsletters', {
      params,
    });

  getNewsletterById = (userId) => Api.get(`/newsletters/${userId}`);

  updateNewsletterById(userId, params) {
    return Api.put(`/newsletters/${userId}`, params);
  }

  createNewsletter(mediator) {
    // return new Promise((resolve) => setTimeout(() => resolve(), 500));
    return Api.post('/newsletters', mediator);
  }

  deleteNewsletter(mediatorId) {
    return Api.delete('/newsletters', { data: { id: mediatorId } });
  }
}

export default new NewslettersService();
