import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, Row, Col } from 'reactstrap';

import { logUserIn } from '../../../store/loggedUser';
import Input from './Input';
import { validateAll } from '../../../library/utils/validations';
import useValidateField from '../../../library/hooks/useValidateField';
import CheckBox from '../../../components/CheckBox';

import styles from './login.module.scss';

export default function Login({ history }) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const validateField = useValidateField(formRef);

  const handleSubmit = (form) => {
    const [formErrors, isValid] = validateAll(form);

    if (isValid) {
      dispatch(logUserIn(form));
      return;
    }
    console.log(formErrors);

    formRef.current.setErrors(formErrors);
  };

  return (
    <div className={styles.container}>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 pt-5">
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                iconLeft="ni ni-email-83"
                name="email"
                placeholder="Email"
                type="email"
                autoComplete="new-email"
                validateField={validateField}
              />
              <Input
                iconLeft="ni ni-lock-circle-open"
                name="password"
                placeholder="Senha"
                type="password"
                autoComplete="new-password"
                validateField={validateField}
              />
              <CheckBox text="Lembrar usuário" />
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  style={{
                    backgroundColor: '#ff810a',
                    border: 'none',
                  }}
                >
                  Sign in
                </Button>
              </div>
            </Form>
            <Row>
              <Col xs="6">
                <a
                  className={styles.forgotPasswordLink}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/auth/forgot-password');
                  }}
                >
                  <small>Esqueceu sua senha?</small>
                </a>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}
