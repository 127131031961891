import Api from '../library/api';

class CoursesService {
  getCourseList = (params) =>
    Api.get('/courses', {
      params,
    });

  getCourseById = (userId) => Api.get(`/courses/${userId}`);

  updateCourseById(userId, params) {
    return Api.put(`/courses/${userId}`, params);
  }

  createCourse(course) {
    return Api.post('/courses', course);
  }

  addClassroomToCourse(course_id, classroom_id) {
    return Api.patch(`/courses/classrooms/${course_id}`, { classroom_id });
  }

  removeCourseFromClassroom(course_id) {
    return Api.delete(`/courses/classrooms`, { data: { course_id } });
  }

  deleteCourse(courseId) {
    return Api.delete('/courses', { data: { id: courseId } });
  }

  createClassOfCourse(courseId, classOfCourse) {
    return Api.post(`/courses/${courseId}/lectures`, classOfCourse);
  }

  getClassesOfCourse(courseId, params) {
    return Api.get(`/courses/${courseId}/lectures`, { params });
  }

  deleteClassOfCourse(classId) {
    return Api.delete('/courses/lectures', { data: { id: classId } });
  }

  addTagToCourse(courseId, tag_id) {
    return Api.post(`/courses/${courseId}/tags`, { tag_id });
  }

  removeTagFromCourse(courseId, tag_id) {
    return Api.delete(`/courses/${courseId}/tags`, { data: { tag_id } });
  }
}

export default new CoursesService();
