import { createSlice } from '@reduxjs/toolkit';

import {
  getDebateDetails,
  updateDebateById,
  createDebate,
  deleteDebate,
} from './thunkActions';

const initialState = {
  data: null,
  fetchState: {
    isLoading: false,
    hasError: false,
  },
};

const debateDetails = createSlice({
  name: 'root/debateDetails',
  initialState,
  reducers: {
    resetDebate: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getDebateDetails.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getDebateDetails.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.data = action.payload;
    },
    [getDebateDetails.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },

    [updateDebateById.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [updateDebateById.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
    },
    [updateDebateById.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },

    [createDebate.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export { getDebateDetails, updateDebateById, createDebate, deleteDebate };

export const selectDebateDetails = (state) => state.debateDetails.data;

export const selectDebateDetailsFetchState = (state) =>
  state.debateDetails.fetchState;

export const { resetDebate } = debateDetails.actions;

export default debateDetails.reducer;
