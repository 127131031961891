import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { getMediatorList } from './thunkActions';

const mediatorListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const mediators = createSlice({
  name: 'root/mediatorList',
  initialState: mediatorListAdapter.getInitialState(initialState),
  reducers: {
    resetMediatorsList: (state) => {
      mediatorListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getMediatorList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getMediatorList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      mediatorListAdapter.setAll(state, action.payload.mediators);
    },
    [getMediatorList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
  },
});

export { getMediatorList };

export const { resetMediatorsList } = mediators.actions;

export const mediatorListSelectors = mediatorListAdapter.getSelectors(
  (state) => state.mediatorList,
);

export const selectMediatorListFetchState = (state) =>
  state.mediatorList.fetchState;

export default mediators.reducer;
