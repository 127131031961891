import React from 'react';
import { Button } from 'reactstrap';

import Modal from '../Modal';

export default function SimplePopUp({
  isOpen,
  onCancelClick,
  children,
  onConfirmClick,
  confirmButtonText = '',
}) {
  return (
    <Modal isOpen={isOpen}>
      <div
        style={{
          width: 500,
          height: 200,
          background: '#fafafa',
          fontWeight: '500',
          fontSize: 22,
          padding: 20,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <div style={{ flex: 1, width: '100%' }}>{children}</div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            color="secondary"
            style={{ height: 40, marginTop: 6, marginRight: 8 }}
            onClick={(e) => {
              e.preventDefault();
              onCancelClick && onCancelClick();
            }}
            size="sm"
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            style={{ height: 40, marginTop: 6, marginRight: 8 }}
            onClick={(e) => {
              e.preventDefault();
              onConfirmClick && onConfirmClick();
            }}
            size="sm"
          >
            {confirmButtonText || 'Confirmar'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
