import { createSlice } from '@reduxjs/toolkit';

import {
  getClassroomDetails,
  updateClassroomById,
  createClassroom,
  AddStundentToClassroom,
  removeStundentToClassroom,
} from './thunkActions';

const initialState = {
  data: null,
  fetchState: {
    isLoading: false,
    hasError: false,
  },
};

const classroomDetails = createSlice({
  name: 'root/classroomDetails',
  initialState,
  reducers: {
    resetClassroom: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getClassroomDetails.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getClassroomDetails.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.data = action.payload;
    },
    [getClassroomDetails.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },

    [updateClassroomById.fulfilled]: (state, action) => {
      state.data = action.payload;
    },

    [createClassroom.fulfilled]: (state, action) => {
      state.data = action.payload;
    },

    [AddStundentToClassroom.fulfilled]: (state, action) => {
      state.data = action.payload;
    },

    [removeStundentToClassroom.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export {
  getClassroomDetails,
  updateClassroomById,
  createClassroom,
  AddStundentToClassroom,
  removeStundentToClassroom,
};

export const selectClassroomDetails = (state) => state.classroomDetails.data;

export const selectClassroomDetailsFetchState = (state) =>
  state.classroomDetails.fetchState;

export const { resetClassroom } = classroomDetails.actions;

export default classroomDetails.reducer;
