import { useCallback } from 'react';
import formatDate from '../utils/formatDate';

const useFormatDate = () =>
  useCallback(
    (date, format = 'DD/MM/YYYY [às] HH:mm') => formatDate(date, format),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

export default useFormatDate;
