import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { FaTrash } from 'react-icons/fa';

import { useDispatch } from 'react-redux';

import TableCard from '../TableCard';
import ChooseTagModal from '../ChooseTagModal';

import styles from './styles.module.scss';
import DebatesService from '../../services/DebatesService';
import CoursesService from '../../services/CoursesService';

import RemoveTagFromDebateModal from './RemoveTagModal';
import { handleErrorMessage } from '../../library/utils/errorHandler';
import { showToast } from '../../store/toast';
import { showLoader, hideLoader } from '../../store/loader';

const ChooseTagsTable = ({ type, id }) => {
  const tagType = useMemo(() => {
    return type === 'course'
      ? { label: 'Curso', type: 'course' }
      : { label: 'Debate', type: 'debate' };
  }, [type]);

  const dispatch = useDispatch();

  const [chooseTagModalIsOpen, setChooseTagModalIsOpen] = useState();

  const removeTagFromDebateModalRef = useRef(null);

  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getTagList = useCallback(async () => {
    setIsLoading(true);
    const response =
      tagType.type === 'debate'
        ? await DebatesService.getDebateById(id)
        : await CoursesService.getCourseById(id);

    setTags(response.data.tags);
    setIsLoading(false);
  }, [id, tagType.type]);

  const handleTagChosen = useCallback(
    async (tag) => {
      try {
        showLoader();
        setChooseTagModalIsOpen(false);

        if (tagType.type === 'debate') {
          await DebatesService.addTagToDebate(id, tag._id);
        } else {
          await CoursesService.addTagToCourse(id, tag._id);
        }

        hideLoader();

        dispatch(
          showToast({
            type: 'success',
            text: 'Tag adcionada com sucesso',
          }),
        );

        getTagList();
      } catch (error) {
        setChooseTagModalIsOpen(true);
        handleErrorMessage(error);
        hideLoader();
        dispatch(
          showToast({
            type: 'danger',
            text: 'Erro ao adcionar Tag',
          }),
        );
      }
    },
    [id, dispatch, getTagList, tagType.type],
  );

  useEffect(() => {
    getTagList();
  }, [getTagList]);

  return (
    <>
      <TableCard
        isLoading={isLoading}
        title={`Lista de Tags do ${tagType.label}`}
        showHeaderButton
        headerButtonClick={() => setChooseTagModalIsOpen(true)}
        style={{ marginTop: 20 }}
        headerButtonText="Adcionar Nova Tag"
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Remover?</th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag) => (
            <tr key={tag._id}>
              <td>{tag.name}</td>
              <td>
                <FaTrash
                  className={styles.trashIcon}
                  onClick={() => removeTagFromDebateModalRef.current.open(tag)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </TableCard>
      <ChooseTagModal
        isOpen={chooseTagModalIsOpen}
        onTagChosen={handleTagChosen}
        onCancelPress={() => setChooseTagModalIsOpen(false)}
        type={tagType.type}
      />
      <RemoveTagFromDebateModal
        ref={removeTagFromDebateModalRef}
        id={id}
        onDebateDeleted={getTagList}
        type={tagType.type}
      />
    </>
  );
};

export default ChooseTagsTable;
