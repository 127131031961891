import Api from '../library/api';

class ProfessorsService {
  getProfessorList = (params) =>
    Api.get('/teachers', {
      params,
    });

  getProfessorById = (userId) => Api.get(`/teachers/${userId}`);

  updateProfessorById(userId, params) {
    return Api.put(`/teachers/${userId}`, params);
  }

  createProfessor(professor) {
    return Api.post('/teachers', professor);
  }

  deleteProfessor(professorId) {
    return Api.delete('/teachers', { data: { id: professorId } });
  }
}

export default new ProfessorsService();
