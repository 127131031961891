export default {
  ADMIN_ADMINS: '/admin/admins',
  ADMIN_SUBSCRIPTIONS: '/admin/subscriptions',
  ADMIN_HOME: '/admin/home',
  ADMIN_MEDIATORS: '/admin/mediators',
  ADMIN_PROFESSORS: '/admin/professors',
  ADMIN_STUDENTS: '/admin/students',
  ADMIN_DEBATES: '/admin/debates',
  ADMIN_CLASSROOMS: '/admin/classrooms',
  ADMIN_NEWSLETTERS: '/admin/newsletters',
  ADMIN_FORMS: '/admin/forms',
  ADMIN_DEBATES_TAGS: '/admin/debate-tags',
  ADMIN_COURSES_TAGS: '/admin/course-tags',
  ADMIN_COURSES: '/admin/courses',
};
