import { createAsyncThunk } from '@reduxjs/toolkit';

import CoursesService from '../../../services/CoursesService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';
import { showLoader, hideLoader } from '../../loader';
import { showToast } from '../../toast';

export const getSubCourseList = createAsyncThunk(
  'subCourseList/getSubCourseList',
  async (params = {}, { dispatch, getState }) => {
    const _params = {
      order: 'asc',
      orderby: 'date',
    };

    const page = params.pageIndex || 1;

    _params.limit = params.limit || 20;
    _params.skip = (page - 1) * _params.limit;

    const debateId = getState().courseDetails.data._id;

    try {
      const response = await CoursesService.getClassesOfCourse(
        debateId,
        _params,
      );

      return {
        ...response.data,
        page,
        limit: _params.limit,
      };
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createClassOfCourse = createAsyncThunk(
  'subCourseList/createClassOfCourse',
  async ({ id, ...params }, { dispatch }) => {
    try {
      dispatch(showLoader());

      const classOfCourse = await CoursesService.createClassOfCourse(
        id,
        params,
      );

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Aula do curso criado com sucesso!',
        }),
      );
      return classOfCourse.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const deleteClassOfCourse = createAsyncThunk(
  'subCourseList/deleteClassOfCourse',
  async (classId, { dispatch }) => {
    try {
      dispatch(showLoader());

      await CoursesService.deleteClassOfCourse(classId);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Aula do curso deletada com sucesso!',
        }),
      );

      return classId;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
