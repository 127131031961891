import { useCallback } from 'react';
import _ from 'lodash';

const useOnChangeText = (inputRef, fieldName, error, validateField) => {
  const debbouncedValidateField = useCallback(
    _.debounce(
      (text) => validateField && validateField(fieldName, text, error),
      1500,
    ),
    [validateField],
  );

  const onChangeText = useCallback(
    (text) => {
      if (validateField) {
        if (error) {
          validateField(fieldName, text, error);
        } else {
          debbouncedValidateField(text);
        }
      }

      if (inputRef.current) {
        inputRef.current.value = text;
      }
    },
    [validateField, error, fieldName, inputRef, debbouncedValidateField],
  );

  return onChangeText;
};

export default useOnChangeText;
