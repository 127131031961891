import Api from '../library/api';

class FormsService {
  getFormList = (params) =>
    Api.get('/forms', {
      params,
    });

  getFormById = (userId) => Api.get(`/forms/${userId}`);

  deleteForm(mediatorId) {
    return Api.delete('/forms', { data: { id: mediatorId } });
  }
}

export default new FormsService();
