import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrash } from 'react-icons/fa';

import {
  getNewslettersList,
  newslettersListSelectors,
  selectNewslettersListFetchState,
  resetNewslettersList,
  deleteNewsletter,
} from '../../../../store/newsletters/newslettersList';
import { TableCard, DisplayStatus, SimplePopUp } from '../../../../components';

import styles from './styles.module.scss';
import useDebounce from '../../../../library/hooks/useDebounce';

const NewslettersTable = () => {
  const dispatch = useDispatch();
  const lsNewsletter = useSelector(newslettersListSelectors.selectAll);
  const fetchState = useSelector(selectNewslettersListFetchState);

  const [newsletterMarkedToDelete, setNewsletterMarkedToDelete] = useState(
    null,
  );

  const [search, setSearch] = useState('');

  const handleDelete = useCallback(() => {
    dispatch(deleteNewsletter(newsletterMarkedToDelete._id));
    setNewsletterMarkedToDelete(null);
  }, [dispatch, newsletterMarkedToDelete]);

  useDebounce(() => dispatch(getNewslettersList({ name: search })), [search], {
    debounceAtStart: false,
  });

  const goToPage = useCallback(
    (pageIndex) => dispatch(getNewslettersList({ pageIndex })),
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(resetNewslettersList());
    };
  }, [dispatch]);

  return (
    <>
      <div className={styles.backgroundTop} />
      <div className={styles.container}>
        <div className="col">
          <div className="row align-items-center">
            <div>
              <input
                value={search}
                className={styles.search}
                placeholder="Buscar Newsletter..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <TableCard
            isLoading={fetchState.isLoading}
            page={fetchState.page}
            totalPages={fetchState.totalPages}
            onPageClick={goToPage}
            onNextPageClick={goToPage}
            onPreviusPageClick={goToPage}
            title="Lista de Newsletters"
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col">Deletar?</th>
              </tr>
            </thead>
            <tbody>
              {lsNewsletter.map((newsletter) => (
                <tr key={newsletter._id}>
                  <td>{newsletter.email}</td>
                  <td>
                    <DisplayStatus status={newsletter.status} />
                  </td>
                  <td>
                    <button
                      className={styles.removeButton}
                      type="button"
                      onClick={() => setNewsletterMarkedToDelete(newsletter)}
                    >
                      <FaTrash size={20} color="#8898aa" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCard>
        </div>
      </div>

      {!!newsletterMarkedToDelete && (
        <SimplePopUp
          isOpen
          onCancelClick={() => setNewsletterMarkedToDelete(null)}
          onConfirmClick={handleDelete}
        >
          {' '}
          <span>
            Tem certeza que deseja deletar a newsletter{' '}
            <span style={{ fontWeight: 'bold' }}>
              {newsletterMarkedToDelete.email}
            </span>
            ?
          </span>
        </SimplePopUp>
      )}
    </>
  );
};

export default NewslettersTable;
