import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  setLoggedUser,
  selectIsUserLogged,
  logOutUser,
} from '../store/loggedUser';
import { setTokenId } from '../store/configs';
import { tokenIterceptor } from '../library/api/interceptors';
import localStorageKeys from '../library/enums/localStorageKeys';
import AdminRoutes from './adminRoutes';
import AuthRoutes from './authRoutes';

export default function Routes() {
  const [firstLoad, setFirstLoad] = useState(true);
  const dispatch = useDispatch();

  const isUserLogged = useSelector(selectIsUserLogged);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(localStorageKeys.LOGGED_USER));
    if (firstLoad) {
      if (user && !isUserLogged) {
        const tokenId = tokenIterceptor(user.token, () =>
          dispatch(logOutUser()),
        );
        delete user.token;
        dispatch(setTokenId(tokenId));
        dispatch(setLoggedUser(user));
      } else setFirstLoad(false);
    }
  }, [firstLoad, isUserLogged, dispatch]);

  if (firstLoad) return null;

  return (
    <HashRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthRoutes {...props} />} />
        <Route path="/admin" render={(props) => <AdminRoutes {...props} />} />
        <Redirect from="/" to="/auth/login" />
      </Switch>
    </HashRouter>
  );
}
