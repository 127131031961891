import React from 'react';

import styles from './radio-list.module.scss';

export default function RadioList({
  options = [],
  title = '',
  onValueChange,
  value,
}) {
  const selectedIndex = options.findIndex((item) => item.value === value);

  return (
    <div className={styles.form}>
      <label className="form-control-label">{title}</label>
      {options.map((item, index) => (
        <button
          key={item.value}
          type="button"
          className={styles.radio}
          onClick={() => {
            onValueChange && onValueChange(item.value);
          }}
        >
          <label>
            <div className={styles.outlineCircle}>
              {selectedIndex === index && <div className={styles.circle} />}
            </div>
            <span style={{ fontSize: 15 }}>{item.label}</span>
          </label>
        </button>
      ))}
    </div>
  );
}
