import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import navigationKeys from '../../../../library/enums/navigationKeys';
import {
  getAdminList,
  adminListSelectors,
  selectAdminListFetchState,
  resetAdminsList,
} from '../../../../store/admins/adminsList';
import { TableCard, DisplayStatus } from '../../../../components';

import styles from './admins-table.module.scss';

const AdminsTable = ({ history }) => {
  const dispatch = useDispatch();
  const lsAdmins = useSelector(adminListSelectors.selectAll);

  const fetchState = useSelector(selectAdminListFetchState);

  const [search, setSearch] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(
      () => dispatch(getAdminList({ name: search })),
      700,
    );
    return () => {
      clearTimeout(timeoutId);
    };
  }, [search, dispatch]);

  const goToPage = useCallback(
    (pageIndex) => dispatch(getAdminList({ pageIndex })),
    [dispatch],
  );

  useEffect(() => {
    return () => {
      dispatch(resetAdminsList());
    };
  }, [dispatch]);

  return (
    <>
      <div className={styles.backgroundTop} />
      <div className={styles.container}>
        <div className="col">
          <div className="row align-items-center">
            <div>
              <input
                value={search}
                className={styles.search}
                placeholder="Buscar Admin..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <TableCard
            isLoading={fetchState.isLoading}
            page={fetchState.page}
            totalPages={fetchState.totalPages}
            onPageClick={goToPage}
            onNextPageClick={goToPage}
            onPreviusPageClick={goToPage}
            title="Lista de Admins"
            showHeaderButton
            headerButtonClick={() =>
              history.push(`${navigationKeys.ADMIN_ADMINS}/create`)
            }
            headerButtonText="Criar Novo Admin"
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {lsAdmins.map((user) => (
                <tr
                  key={user._id}
                  className={styles.tableLine}
                  onClick={() =>
                    history.push(`${navigationKeys.ADMIN_ADMINS}/${user._id}`)
                  }
                >
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    <DisplayStatus status={user.status} />
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCard>
        </div>
      </div>
    </>
  );
};

export default AdminsTable;
