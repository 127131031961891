import React from 'react';
import Spinner from 'react-spinkit';

import styles from './content-loader.module.scss';

function Loading({ isLoading = false, style }) {
  if (!isLoading) return null;

  return (
    <div className={styles.loading_container} style={style}>
      <Spinner
        name="ball-spin-fade-loader"
        color="orange"
        className={styles.loading_icon}
        fadeIn="none"
      />
    </div>
  );
}

export default Loading;
