import React from 'react';
import { Alert } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { selectToastOptions, hideToast } from '../../store/toast/index';

// types: alert-default, primary, secondary, info, success, danger, warning

export default function ToastContainer() {
  const dispatch = useDispatch();
  const toastOptions = useSelector(selectToastOptions);

  return (
    <div style={{ position: 'sticky', top: 10, zIndex: 8 }}>
      <Alert
        style={{ position: 'absolute', right: 10 }}
        isOpen={toastOptions.isOpen}
        color={toastOptions.type ? toastOptions.type : 'primary'}
        toggle={() => dispatch(hideToast())}
        fade={false}
      >
        {toastOptions.text}
      </Alert>
    </div>
  );
}
