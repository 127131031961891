import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import {
  getStudentList,
  studentListSelectors,
  selectStudentListFetchState,
} from '../../../../../store/students/studentList';
import TableCard from '../../../../../components/TableCard';
import getStatusText from '../../../../../library/utils/getStatusText';
import useDebounce from '../../../../../library/hooks/useDebounce';
import { Modal } from '../../../../../components';

import styles from '../styles.module.scss';

const StudentsTable = ({ onStudentClick, isOpen, onCancelPress }) => {
  const dispatch = useDispatch();
  const lsStudents = useSelector(studentListSelectors.selectAll);
  const fetchState = useSelector(selectStudentListFetchState);

  const [studentChoosed, setStudentChoosed] = useState(null);
  const [confirmStudentOpen, setConfirmStudentOpen] = useState(false);

  const [search, setSearch] = useState('');

  useDebounce(
    () => isOpen && dispatch(getStudentList({ name: search })),
    [search, isOpen],
    {
      debounceAtStart: false,
    },
  );

  const goToPage = useCallback(
    (pageIndex) => dispatch(getStudentList({ pageIndex })),
    [dispatch],
  );

  return (
    <Modal isOpen={isOpen}>
      <div className={styles.searhStudentsContainer}>
        <TableCard
          isLoading={fetchState.isLoading}
          page={fetchState.page}
          totalPages={fetchState.totalPages}
          onPageClick={goToPage}
          onNextPageClick={goToPage}
          onPreviusPageClick={goToPage}
          title="Lista de Alunos"
          headerComponent={
            <div className={styles.searchContainer}>
              <div>
                <input
                  value={search}
                  className={styles.search}
                  placeholder="Buscar Aluno..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <Button
                color="primary"
                style={{ height: 40, marginTop: 6, marginRight: 8 }}
                onClick={(e) => {
                  e.preventDefault();
                  onCancelPress && onCancelPress();
                }}
                size="sm"
              >
                Cancelar
              </Button>
            </div>
          }
        >
          <thead className="thead-light">
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Email</th>
              <th scope="col">Telefone</th>
              <th scope="col">Status</th>
              <th scope="col">Matrícula</th>
            </tr>
          </thead>
          <tbody>
            {lsStudents.map((user) => (
              <tr
                key={user._id}
                className={styles.tableLine}
                onClick={() => {
                  setStudentChoosed(user);
                  setConfirmStudentOpen(true);
                }}
              >
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{getStatusText(user.status)}</td>
                <td>{user.enrollment}</td>
              </tr>
            ))}
          </tbody>
        </TableCard>
      </div>
      <Modal isOpen={confirmStudentOpen}>
        <div
          style={{
            width: 500,
            height: 200,
            background: '#fafafa',
            fontWeight: '500',
            fontSize: 22,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <span style={{ flex: 1 }}>
            Tem certeza que deseja adcionar o Aluno{' '}
            <span style={{ fontWeight: 'bold' }}>
              {studentChoosed && studentChoosed.name}
            </span>{' '}
            a esta turma?
          </span>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              color="secondary"
              style={{ height: 40, marginTop: 6, marginRight: 8 }}
              onClick={(e) => {
                e.preventDefault();
                setConfirmStudentOpen(false);
                setStudentChoosed(null);
              }}
              size="sm"
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              style={{ height: 40, marginTop: 6, marginRight: 8 }}
              onClick={(e) => {
                e.preventDefault();
                setConfirmStudentOpen(false);
                onStudentClick && onStudentClick(studentChoosed);
              }}
              size="sm"
            >
              Adcionar
            </Button>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};

export default StudentsTable;
