import React, { useCallback } from 'react';
// import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css } from 'glamor';

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Nav,
  Media,
} from 'reactstrap';
import { logOutUser, selectLoggedUser } from '../../store/loggedUser';

const Dropdown = () => {
  const dispatch = useDispatch();

  const loggedUser = useSelector(selectLoggedUser);

  const handleLogout = useCallback(() => {
    dispatch(logOutUser());
  }, [dispatch]);

  const rule = css({
    ':hover': {
      cursor: 'pointer',
    },
  });

  return (
    <Nav className="align-items-center d-none d-md-flex" navbar>
      <UncontrolledDropdown nav>
        <DropdownToggle className="pr-0" nav>
          <Media className="align-items-center">
            {/* <span className="avatar avatar-sm rounded-circle">
              <img
                alt="..."
                src={require('../../assets/img/theme/team-4-800x800.jpg')}
              />
  </span> */}
            <Media className="ml-2 d-none d-lg-block">
              <span
                className="mb-0 text-sm font-weight-bold"
                style={{ color: '#fafafa' }}
              >
                {loggedUser.name}
              </span>
            </Media>
          </Media>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          {/* <DropdownItem className="noti-title" header tag="div">
            <h6 className="text-overflow m-0">Welcome!</h6>
          </DropdownItem>
          <DropdownItem to="/admin/user-profile" tag={Link}>
            <i className="ni ni-single-02" />
            <span>My profile</span>
          </DropdownItem>
          <DropdownItem to="/admin/user-profile" tag={Link}>
            <i className="ni ni-settings-gear-65" />
            <span>Settings</span>
          </DropdownItem>
          <DropdownItem to="/admin/user-profile" tag={Link}>
            <i className="ni ni-calendar-grid-58" />
            <span>Activity</span>
          </DropdownItem>
          <DropdownItem to="/admin/user-profile" tag={Link}>
            <i className="ni ni-support-16" />
            <span>Support</span>
          </DropdownItem>
          <DropdownItem divider /> */}
          <DropdownItem onClick={handleLogout} {...rule}>
            <i className="ni ni-user-run" />
            <span>Sair</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

export default Dropdown;
