import { createAsyncThunk } from '@reduxjs/toolkit';

import DebatesService from '../../../services/DebatesService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';

export const getDebateList = createAsyncThunk(
  'debates/getDebateList',
  async (params, { dispatch }) => {
    const _params = {
      order: 'asc',
      orderby: 'date',
    };

    const page = (params && params.pageIndex) || 1;

    _params.limit = (params && params.limit) || 20;
    _params.skip = (page - 1) * _params.limit;

    if (params && params.title) {
      _params.title = params.title;
    }

    if (params && params.top50) {
      _params.top50 = true;
    }

    if (params && params.status) {
      _params.status = params.status;
    }

    if (params && params.category) {
      _params.category = params.category;
    }

    try {
      const response = await DebatesService.getDebateList(_params);

      return {
        ...response.data,
        page,
        limit: _params.limit,
      };
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
