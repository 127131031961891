import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import navigationKeys from '../../../../library/enums/navigationKeys';
import {
  getDebateList,
  debateListSelectors,
  selectDebateListFetchState,
  resetDebatesList,
} from '../../../../store/debates/debateList';
import { TableCard, DisplayStatus, CheckBox } from '../../../../components';
import useDebounce from '../../../../library/hooks/useDebounce';

import styles from './debates-table.module.scss';

const DebatesTable = ({ history }) => {
  const dispatch = useDispatch();
  const lsDebates = useSelector(debateListSelectors.selectAll);
  const fetchState = useSelector(selectDebateListFetchState);

  const [top50, setTop50] = useState(false);

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getDebateList({ title: search, top50 }));
  }, [top50, dispatch, search]);

  useDebounce(() => dispatch(getDebateList({ title: search })), [search], {
    debounceAtStart: true,
  });

  const goToPage = useCallback(
    (pageIndex) => dispatch(getDebateList({ pageIndex, title: search })),
    [dispatch, search],
  );

  useEffect(() => {
    dispatch(resetDebatesList());
  }, [dispatch]);

  return (
    <>
      <div className={styles.backgroundTop} />
      <div className={styles.container}>
        <div className="col">
          <div className="row">
            <div>
              <input
                value={search}
                className={styles.search}
                placeholder="Buscar Debate..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className={styles.checkBoxContainer}>
              <CheckBox
                text="Top50"
                spanStyle={{ color: '#fafafa' }}
                value={top50}
                onClick={() => setTop50(!top50)}
              />
            </div>
          </div>
          <TableCard
            isLoading={fetchState.isLoading}
            page={fetchState.page}
            totalPages={fetchState.totalPages}
            onPageClick={goToPage}
            onNextPageClick={goToPage}
            onPreviusPageClick={goToPage}
            title="Lista de Debates"
            showHeaderButton
            headerButtonClick={() =>
              history.push(`${navigationKeys.ADMIN_DEBATES}/create`)
            }
            headerButtonText="Criar Novo Debate"
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Título</th>
                <th scope="col">Tipo</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {lsDebates.map((debate) => (
                <tr
                  key={debate._id}
                  className={styles.tableLine}
                  onClick={() =>
                    history.push(
                      `${navigationKeys.ADMIN_DEBATES}/${debate._id}`,
                    )
                  }
                >
                  <td>{debate.title}</td>
                  <td>{debate.category}</td>
                  <td>
                    <DisplayStatus status={debate.status} />
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCard>
        </div>
      </div>
    </>
  );
};

export default DebatesTable;
