import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import navigationKeys from '../../../../library/enums/navigationKeys';
import {
  getClassroomList,
  classroomListSelectors,
  selectClassroomListFetchState,
} from '../../../../store/classrooms/classroomList';
import { TableCard, DisplayStatus } from '../../../../components';

import styles from './classroom-table.module.scss';
import useDebounce from '../../../../library/hooks/useDebounce';
import daysOfWeek from '../utils/daysOfWeek';

const ClassroomsTable = ({ history }) => {
  const dispatch = useDispatch();
  const lsClassroom = useSelector(classroomListSelectors.selectAll);
  const fetchState = useSelector(selectClassroomListFetchState);

  const [search, setSearch] = useState('');

  useDebounce(() => dispatch(getClassroomList({ name: search })), [search], {
    debounceAtStart: false,
  });

  const goToPage = useCallback(
    (pageIndex) => dispatch(getClassroomList({ pageIndex, name: search })),
    [dispatch, search],
  );

  const getDayOfWeekLabel = useCallback((dayOfWeek) => {
    const findDayOfWeek = daysOfWeek.find((item) => item.value === dayOfWeek);

    if (!findDayOfWeek) {
      return undefined;
    }

    return findDayOfWeek.label;
  }, []);

  return (
    <>
      <div className={styles.backgroundTop} />
      <div className={styles.container}>
        <div className="col">
          <div className="row align-items-center">
            <div>
              <input
                value={search}
                className={styles.search}
                placeholder="Buscar Turmas..."
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <TableCard
            isLoading={fetchState.isLoading}
            page={fetchState.page}
            totalPages={fetchState.totalPages}
            onPageClick={goToPage}
            onNextPageClick={goToPage}
            onPreviusPageClick={goToPage}
            title="Lista de Turmas"
            showHeaderButton
            headerButtonClick={() =>
              history.push(`${navigationKeys.ADMIN_CLASSROOMS}/create`)
            }
            headerButtonText="Criar Nova Turma"
          >
            <thead className="thead-light">
              <tr>
                <th scope="col">Nome</th>
                <th>Dia da Semana</th>
                <th>Hora</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {lsClassroom.map((classroom) => (
                <tr
                  key={classroom._id}
                  className={styles.tableLine}
                  onClick={() =>
                    history.push(
                      `${navigationKeys.ADMIN_CLASSROOMS}/${classroom._id}`,
                    )
                  }
                >
                  <td>{classroom.name}</td>
                  <td>{getDayOfWeekLabel(classroom.dayOfWeek)}</td>
                  <td>{moment(classroom.datetime).format('HH:mm')}</td>
                  <td>
                    <DisplayStatus status={classroom.status} />
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCard>
        </div>
      </div>
    </>
  );
};

export default ClassroomsTable;
