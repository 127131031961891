import React, {
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { Form } from '@unform/web';
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { SimplePopUp, Input, RadioList } from '../../../../components';
import { createTag, updateTagById } from '../../../../store/tags/tagsList';

// eslint-disable-next-line no-empty-pattern
const TagFormModal = ({}, ref) => {
  const [tagStatus, setTagStatus] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const [defaultValue, setDefaultValue] = useState(null);

  useImperativeHandle(ref, () => ({
    create: () => {
      setIsOpen(true);
    },
    update: (tag) => {
      setDefaultValue(tag);

      setTagStatus(tag.status);
      setIsOpen(true);
    },
  }));

  const resetModal = useCallback(() => {
    setTagStatus(true);
    setDefaultValue(null);
  }, []);

  const handleSubmit = useCallback(
    async (form) => {
      const tag = {
        name: form.name,
        type: 'debate',
      };

      setIsOpen(false);

      if (defaultValue) {
        tag.status = tagStatus;
        tag.id = defaultValue._id;

        dispatch(updateTagById(tag)).then((action) => {
          if (action.payload) {
            resetModal();
          } else {
            setIsOpen(true);
          }
        });

        return;
      }

      dispatch(createTag(tag)).then((action) => {
        if (action.payload) {
          resetModal();
        } else {
          setIsOpen(true);
        }
      });
    },
    [dispatch, tagStatus, defaultValue, resetModal],
  );

  if (!isOpen) {
    return null;
  }

  return (
    <SimplePopUp
      isOpen
      confirmButtonText="Salvar"
      onCancelClick={() => setIsOpen(false)}
      onConfirmClick={() => formRef.current.submitForm()}
    >
      <Form onSubmit={handleSubmit} ref={formRef} initialData={defaultValue}>
        <Row>
          <Col lg="6">
            {' '}
            <Input
              // validateField={validateField}
              label="Nome"
              name="name"
              placeholder="Digite o nome..."
              className="form-control-alternative"
              type="text"
            />
          </Col>

          <Col lg="6">
            <RadioList
              onValueChange={(value) => setTagStatus(value)}
              value={tagStatus}
              title="Status"
              options={[
                { label: 'Ativo', value: true },
                { label: 'Inativo', value: false },
              ]}
            />
          </Col>
        </Row>
      </Form>
    </SimplePopUp>
  );
};

export default forwardRef(TagFormModal);
