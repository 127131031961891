import Api from '../library/api';

class DebatessService {
  getDebateList = (params) =>
    Api.get('/debates', {
      params,
    });

  getDebateById = (userId) => Api.get(`/debates/${userId}`);

  updateDebateById(userId, params) {
    return Api.put(`/debates/${userId}`, params);
  }

  createDebate(debate) {
    return Api.post('/debates', debate);
  }

  addClassroomToDebate(debate_id, classroom_id) {
    return Api.patch(`/debates/classrooms/${debate_id}`, { classroom_id });
  }

  removeDebateFromClassroom(debate_id) {
    return Api.delete(`/debates/classrooms`, { data: { debate_id } });
  }

  deleteDebate(debateId) {
    return Api.delete('/debates', { data: { id: debateId } });
  }

  createClassOfDebate(debateId, classOfDebate) {
    return Api.post(`/debates/${debateId}/classes`, classOfDebate);
  }

  getClassesOfDebate(debateId, params) {
    return Api.get(`/debates/${debateId}/classes`, { params });
  }

  deleteClassOfDebate(classId) {
    return Api.delete('/debates/classes', { data: { id: classId } });
  }

  addTagToDebate(debateId, tag_id) {
    return Api.post(`/debates/${debateId}/tags`, { tag_id });
  }

  removeTagFromDebate(debateId, tag_id) {
    return Api.delete(`/debates/${debateId}/tags`, { data: { tag_id } });
  }
}

export default new DebatessService();
