import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import { Button, Card, CardBody, Col } from 'reactstrap';

import { resetPassword } from '../../../store/loggedUser';
import Input from '../Login/Input';
import { validateAll } from '../../../library/utils/validations';
import useValidateField from '../../../library/hooks/useValidateField';

import styles from './reset-password.module.scss';

export default function ResetPassword({ history, location }) {
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const dispatch = useDispatch();
  const formRef = useRef(null);

  const validateField = useValidateField(formRef);

  const handleSubmit = (form) => {
    const [formErrors, isValid] = validateAll(form);

    if (isValid) {
      dispatch(
        resetPassword({
          password: form.newPassword,
          password_confirmation: form.confirmNewPassword,
          token,
        }),
      )
        .then((data) => {
          if (!data.error) {
            history.push('/auth/login');
          }
        })
        .catch(() => console.log('error'));
      return;
    }
    console.log(formErrors);

    formRef.current.setErrors(formErrors);
  };

  return (
    <div className={styles.container}>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form ref={formRef} onSubmit={handleSubmit}>
              {/* <Input
                iconLeft="ni ni-email-83"
                name="email"
                placeholder="Email"
                type="email"
                autoComplete="new-email"
                validateField={validateField}
              /> */}
              <Input
                name="newPassword"
                iconLeft="ni ni-lock-circle-open"
                placeholder="Nova Senha"
                type="password"
                autoComplete="new-password"
                validateField={validateField}
              />
              <Input
                name="confirmNewPassword"
                iconLeft="ni ni-lock-circle-open"
                placeholder="Confirmar Nova Senha"
                type="password"
                autoComplete="new-password"
                validateField={validateField}
              />

              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  style={{
                    backgroundColor: '#ff810a',
                    border: 'none',
                  }}
                >
                  Enviar
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
}
