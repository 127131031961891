import Api from '../library/api';

class MediatorsService {
  getMediatorList = (params) =>
    Api.get('/mediators', {
      params,
    });

  getMediatorById = (userId) => Api.get(`/mediators/${userId}`);

  updateMediatorById(userId, params) {
    return Api.put(`/mediators/${userId}`, params);
  }

  createMediator(mediator) {
    // return new Promise((resolve) => setTimeout(() => resolve(), 500));
    return Api.post('/mediators', mediator);
  }

  deleteMediator(mediatorId) {
    return Api.delete('/mediators', { data: { id: mediatorId } });
  }
}

export default new MediatorsService();
