import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { getFormsList, deleteForm } from './thunkActions';

const formsListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const formss = createSlice({
  name: 'root/formsList',
  initialState: formsListAdapter.getInitialState(initialState),
  reducers: {
    resetFormsList: (state) => {
      formsListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getFormsList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getFormsList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      formsListAdapter.setAll(state, action.payload.data);
    },
    [getFormsList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
    [deleteForm.fulfilled]: (state, payload) => {
      formsListAdapter.removeOne(state, payload);
    },
  },
});

export { getFormsList, deleteForm };

export const { resetFormsList } = formss.actions;

export const formsListSelectors = formsListAdapter.getSelectors(
  (state) => state.formsList,
);

export const selectFormsListFetchState = (state) => state.formsList.fetchState;

export default formss.reducer;
