import Api from '../library/api';

class AdminsService {
  getAdminList = (params) =>
    Api.get('/users', {
      params,
    });

  getAdminById = (userId) => Api.get(`/users/${userId}`);

  updateAdminById(userId, params) {
    return Api.put(`/users/${userId}`, params);
  }

  createAdmin = (admin) => Api.post(`/users`, admin);
}

export default new AdminsService();
