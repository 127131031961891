import { createAsyncThunk } from '@reduxjs/toolkit';

import { showLoader, hideLoader } from '../loader';
import { showToast } from '../toast';
import AccountService from '../../services/AccountService';
import localStorageKeys from '../../library/enums/localStorageKeys';
import { tokenIterceptor } from '../../library/api/interceptors';
import { setTokenId, resetTokenId, selectTokenId } from '../configs';

import { handleErrorMessage } from '../../library/utils/errorHandler';
import Api from '../../library/api';

export const forgotPassword = createAsyncThunk(
  'logged-user/forgotPassword',
  async (email, { dispatch }) => {
    try {
      dispatch(showLoader());

      const response = await AccountService.forgotPassword(email);
      dispatch(hideLoader());
      dispatch(
        showToast({ type: 'success', text: 'Email enviado com sucesso' }),
      );
      return response;
    } catch (error) {
      dispatch(hideLoader());
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const resetPassword = createAsyncThunk(
  'logged-user/resetPassword',
  async (body, { dispatch }) => {
    try {
      dispatch(showLoader());
      console.log('body', body);
      const response = await AccountService.resetPassword(body);
      dispatch(hideLoader());
      dispatch(
        showToast({ type: 'success', text: 'Senha Alterada com sucesso' }),
      );
      return response;
    } catch (error) {
      dispatch(hideLoader());
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const logOutUser = createAsyncThunk(
  'logged-user/logOutUser',
  async (_, { dispatch, getState }) => {
    const tokenId = getState(selectTokenId);
    Api.interceptors.request.eject(tokenId);
    dispatch(resetTokenId());
    localStorage.removeItem(localStorageKeys.LOGGED_USER);
  },
);

export const logUserIn = createAsyncThunk(
  'logged-user/login',
  async ({ email, password }, { dispatch }) => {
    try {
      dispatch(showLoader());

      const response = await AccountService.login(email, password);
      const id = tokenIterceptor(response.data.token, () =>
        dispatch(logOutUser()),
      );
      dispatch(setTokenId(id));

      localStorage.setItem(
        localStorageKeys.LOGGED_USER,
        JSON.stringify({ ...response.data.user, token: response.data.token }),
      );

      dispatch(hideLoader());

      return response.data.user;
    } catch (error) {
      dispatch(hideLoader());
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
