import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { getCourseList } from './thunkActions';

const courseListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const courses = createSlice({
  name: 'courses/courseList',
  initialState: courseListAdapter.getInitialState(initialState),
  reducers: {
    resetCoursesList: (state) => {
      courseListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getCourseList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getCourseList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      courseListAdapter.setAll(state, action.payload.data);
    },
    [getCourseList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
  },
});

export { getCourseList };

export const { resetCoursesList } = courses.actions;

export const courseListSelectors = courseListAdapter.getSelectors(
  (state) => state.courseList,
);

export const selectCourseListFetchState = (state) =>
  state.courseList.fetchState;

export default courses.reducer;
