import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../components/Input';
import ContentLoader from '../../../../components/ContentLoader';
import {
  selectAdminDetails,
  selectAdminDetailsFetchState,
  getAdminDetails,
  updateAdminById,
  resetAdminDetails,
  createAdmin,
} from '../../../../store/admins/adminsDetails';
import { validateAll } from '../../../../library/utils/validations';
import { showToast } from '../../../../store/toast/index';
import useFomInitialData from '../../../../library/hooks/useFomInitialData';
import useValidateField from '../../../../library/hooks/useValidateField';
import navigationKeys from '../../../../library/enums/navigationKeys';
import RadioList from '../../../../components/RadioList';

export default function AdminDetails({ match, history }) {
  const adminId = useMemo(() => match.params.userId, [match.params]);
  const [userStatus, setUserStatus] = useState(null);
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const adminDetails = useSelector(selectAdminDetails);

  const validateField = useValidateField(formRef);

  const fetchState = useSelector(selectAdminDetailsFetchState);

  const initialData = useFomInitialData(
    {
      name: '',
      surname: '',
      email: '',
    },
    adminDetails,
  );

  useEffect(() => {
    if (adminDetails) {
      setUserStatus(adminDetails.status);
    }
  }, [adminDetails]);

  useEffect(() => {
    if (adminId) {
      dispatch(getAdminDetails(adminId));
    }

    return () => {
      dispatch(resetAdminDetails());
    };
  }, [adminId, dispatch]);

  const handleSubmit = useCallback(
    (form) => {
      if (userStatus === null) {
        dispatch(
          showToast({
            type: 'danger',
            text: 'Status Obrigatório',
          }),
        );

        return;
      }

      const [formErrors, isValid] = validateAll(form);

      if (!isValid) {
        formRef.current.setErrors(formErrors);
        return;
      }

      if (!adminId) {
        const newAdmin = {
          name: form.name,
          surname: form.surname,
          password: form.newPassword,
          email: form.email,
          status: userStatus,
        };

        dispatch(createAdmin(newAdmin)).then((action) => {
          if (action.payload && action.payload.id) {
            history.push(`${navigationKeys.ADMIN_ADMINS}/${action.payload.id}`);
          }
        });

        return;
      }

      const adminUpdated = {
        id: adminDetails.id,
        name: form.name,
        surname: form.surname,
        email: form.email,
        status: userStatus,
      };

      dispatch(updateAdminById(adminUpdated));
    },
    [dispatch, adminDetails, formRef, adminId, history, userStatus],
  );

  if (fetchState.isLoading) {
    return <ContentLoader isLoading />;
  }

  return (
    <Container>
      <Col style={{ margin: '20px 0' }}>
        <Card className="bg-secondary shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Informações de Admin</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form
              onSubmit={handleSubmit}
              initialData={initialData}
              ref={formRef}
            >
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <Input
                      label="Nome"
                      name="name"
                      className="form-control-alternative"
                      placeholder="Digite o nome..."
                      validateField={validateField}
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      label="Sobrenome"
                      name="surname"
                      className="form-control-alternative"
                      placeholder="Digite o sobrenome..."
                      validateField={validateField}
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      label="Email"
                      name="email"
                      className="form-control-alternative"
                      placeholder="Digite o email"
                      type="email"
                      validateField={validateField}
                    />
                  </Col>
                  {!adminId && (
                    <>
                      <Col lg="6">
                        <Input
                          validateField={validateField}
                          label="Senha"
                          name="newPassword"
                          className="form-control-alternative"
                          placeholder="Digite a senha"
                          type="password"
                          autoComplete="new-password"
                        />
                      </Col>
                      <Col lg="6">
                        <Input
                          validateField={validateField}
                          label="Confirmar Senha"
                          name="confirmNewPassword"
                          className="form-control-alternative"
                          placeholder="Confirme a senha"
                          type="password"
                          autoComplete="new-password"
                        />
                      </Col>
                    </>
                  )}
                  <Col lg="6">
                    <RadioList
                      onValueChange={(value) => setUserStatus(value)}
                      value={userStatus}
                      title="Status"
                      options={[
                        { label: 'Ativo', value: true },
                        { label: 'Inativo', value: false },
                      ]}
                    />
                  </Col>
                </Row>
              </div>
              <Col className="text-right" xs="12">
                <Button color="primary" size="m" type="submit">
                  Salvar
                </Button>
              </Col>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
}
