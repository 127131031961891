import Api from '../library/api';

class SubscriptionssService {
  getSubscriptionList = (params) =>
    Api.get('/mediators', {
      params,
    });

  getSubscriptionById = (userId) => Api.get(`/mediators/${userId}`);

  updateSubscriptionById(userId, params) {
    return Api.put(`/mediators/${userId}`, params);
  }

  createSubscription(subscription) {
    return new Promise((resolve) => setTimeout(() => resolve(), 500));
    // return Api.post('/subscriptions', subscription);
  }
}

export default new SubscriptionssService();
