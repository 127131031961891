import { createAsyncThunk } from '@reduxjs/toolkit';

export const hideToast = createAsyncThunk('toast/hideToast', () => {});

// types: alert-default, primary, secondary, info, success, danger, warning
export const showToast = createAsyncThunk(
  'toast/showToast',
  (params, { dispatch }) => {
    setTimeout(() => dispatch(hideToast()), 3000);
    return params;
  },
);
