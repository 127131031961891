/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from 'reactstrap';
import useOnChangeText from '../../../library/hooks/useOnChangeText';

import styles from './login.module.scss';

export default function Input({
  name,
  validateField,
  iconLeft = '',
  label = '',
  ...inputProps
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const onChangeText = useOnChangeText(
    inputRef,
    fieldName,
    error,
    validateField,
  );

  return (
    <FormGroup className="mb-3">
      {Boolean(label) && <label className="form-control-label">{label}</label>}
      <InputGroup className="input-group-alternative" style={{ height: 46 }}>
        {Boolean(iconLeft) && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className={iconLeft} />
            </InputGroupText>
          </InputGroupAddon>
        )}
        <input
          style={{ paddingLeft: iconLeft ? 0 : 12 }}
          onBlur={() => validateField && validateField(fieldName)}
          onChange={(e) => onChangeText(e.target.value)}
          ref={inputRef}
          defaultValue={defaultValue}
          {...inputProps}
          className={styles.input}
        />
      </InputGroup>
      <div className={styles.errorTextWrapper}>
        {Boolean(error) && <span>{error}</span>}
      </div>
    </FormGroup>
  );
}
