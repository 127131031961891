import { createSlice } from '@reduxjs/toolkit';

const loader = createSlice({
  name: 'root/loader',
  initialState: {
    isLoading: false,
  },
  reducers: {
    showLoader: (state) => {
      state.isLoading = true;
    },
    hideLoader: (state) => {
      state.isLoading = false;
    },
  },
});

const { showLoader, hideLoader } = loader.actions;
const selectLoaderIsLoading = (state) => state.loader.isLoading;

export default loader.reducer;
export { showLoader, hideLoader, selectLoaderIsLoading };
