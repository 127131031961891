import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { getNewslettersList, deleteNewsletter } from './thunkActions';

const newslettersListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const newsletterss = createSlice({
  name: 'root/newslettersList',
  initialState: newslettersListAdapter.getInitialState(initialState),
  reducers: {
    resetNewslettersList: (state) => {
      newslettersListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getNewslettersList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getNewslettersList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      newslettersListAdapter.setAll(state, action.payload.newsletter);
    },
    [getNewslettersList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
    [deleteNewsletter.fulfilled]: (state, payload) => {
      newslettersListAdapter.removeOne(state, payload);
    },
  },
});

export { getNewslettersList, deleteNewsletter };

export const { resetNewslettersList } = newsletterss.actions;

export const newslettersListSelectors = newslettersListAdapter.getSelectors(
  (state) => state.newslettersList,
);

export const selectNewslettersListFetchState = (state) =>
  state.newslettersList.fetchState;

export default newsletterss.reducer;
