import { createSlice } from '@reduxjs/toolkit';

import {
  getMediatorDetails,
  updateMediatorById,
  createMediator,
} from './thunkActions';

const initialState = {
  data: null,
  fetchState: {
    isLoading: false,
    hasError: false,
  },
};

const mediatorDetails = createSlice({
  name: 'root/mediatorDetails',
  initialState,
  reducers: {
    resetMediator: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getMediatorDetails.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getMediatorDetails.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.data = action.payload;
    },
    [getMediatorDetails.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },

    [updateMediatorById.fulfilled]: (state, action) => {
      state.data = action.payload;
    },

    [createMediator.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export { getMediatorDetails, updateMediatorById, createMediator };

export const selectMediatorDetails = (state) => state.mediatorDetails.data;

export const selectMediatorDetailsFetchState = (state) =>
  state.mediatorDetails.fetchState;

export const { resetMediator } = mediatorDetails.actions;

export default mediatorDetails.reducer;
