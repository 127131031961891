import { createAsyncThunk } from '@reduxjs/toolkit';

import DebatesService from '../../../services/DebatesService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';
import { showLoader, hideLoader } from '../../loader';
import { showToast } from '../../toast';

export const getSubDebateList = createAsyncThunk(
  'debates/getSubDebateList',
  async (params = {}, { dispatch, getState }) => {
    const _params = {
      order: 'asc',
      orderby: 'date',
    };

    const page = params.pageIndex || 1;

    _params.limit = params.limit || 20;
    _params.skip = (page - 1) * _params.limit;

    const debateId = getState().debateDetails.data._id;

    try {
      const response = await DebatesService.getClassesOfDebate(
        debateId,
        _params,
      );

      return {
        ...response.data,
        page,
        limit: _params.limit,
      };
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createClassOfDebate = createAsyncThunk(
  'subDebateList/createClassOfDebate',
  async ({ id, ...params }, { dispatch }) => {
    try {
      dispatch(showLoader());

      const classOfDebate = await DebatesService.createClassOfDebate(
        id,
        params,
      );

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Aula do debate criado com sucesso!',
        }),
      );
      return classOfDebate.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const deleteClassOfDebate = createAsyncThunk(
  'subDebateList/deleteClassOfDebate',
  async (classId, { dispatch }) => {
    try {
      dispatch(showLoader());

      await DebatesService.deleteClassOfDebate(classId);

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Aula do debate deletada com sucesso!',
        }),
      );

      return classId;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
