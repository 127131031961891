import Api from '../library/api';

class StudentssService {
  getStudentList = (params) =>
    Api.get('/students', {
      params,
    });

  getStudentById = (userId) => Api.get(`/students/${userId}`);

  updateStudentById(userId, params) {
    return Api.put(`/students/${userId}`, params);
  }

  createStudent(professor) {
    return Api.post('/students', professor);
  }

  deleteStudent(studentId) {
    return Api.delete('/students', { data: { id: studentId } });
  }
}

export default new StudentssService();
