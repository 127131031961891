import { createAsyncThunk } from '@reduxjs/toolkit';

import AdminsService from '../../../services/AdminsService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';

export const getAdminList = createAsyncThunk(
  'userList/getAdminList',
  async (params, { dispatch }) => {
    const _params = {};

    const page = (params && params.pageIndex) || 1;

    _params.limit = (params && params.limit) || 20;
    _params.skip = (page - 1) * _params.limit;

    if (params && params.name) {
      _params.name = params.name;
    }

    try {
      const response = await AdminsService.getAdminList(_params);

      return {
        ...response.data,
        page,
        limit: _params.limit,
      };
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
