import { createSlice } from '@reduxjs/toolkit';
import { showToast, hideToast } from './thunkActions';

const initialState = {
  isOpen: false,
  type: null,
  text: null,
};

const toast = createSlice({
  name: 'root/loader',
  initialState,
  extraReducers: {
    [showToast.fulfilled]: (state, action) => {
      state.isOpen = true;
      state.type = action.payload.type || 'primary';
      state.text = action.payload.text;
    },
    [hideToast.fulfilled]: (state) => {
      state.isOpen = false;
    },
  },
});

const selectToastOptions = (state) => state.toast;

export default toast.reducer;
export { selectToastOptions, showToast, hideToast };
