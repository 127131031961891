function emptyText(value = '') {
  return `Campo ${value} obrigatório`;
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateCPF(cpf) {
  if (!cpf) return false;

  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  let rev = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

export function validatePhoneNumber(value) {
  value = value.replace(/[^\d]+/g, '');
  value = value.replace('(', '');
  value = value.replace(')', '');
  value = value.replace('-', '');
  value = value.replace(' ', '').trim();

  if (value === '0000000000') {
    return false;
  }
  if (value === '00000000000') {
    return false;
  }
  if (
    ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10'].indexOf(
      value.substring(0, 2),
    ) !== -1
  ) {
    return false;
  }
  if (value.length < 10 || value.length > 11) {
    return false;
  }
  if (['6', '7', '8', '9'].indexOf(value.substring(2, 3)) === -1) {
    return false;
  }
  return true;
}

export function validDate(value) {
  if (!value) return false;

  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) return false;

  // Parse the date parts to integers
  const parts = value.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

export function validateField(fieldName, value, formRef) {
  switch (fieldName) {
    case 'email':
      if (value === '' || !value) {
        return emptyText('Email');
      }

      if (!validateEmail(value)) {
        return 'Email inválido';
      }

      return null;
    case 'password':
      if (value === '') {
        return 'Campo Obrigatório';
      }
      return null;
    case 'newPassword':
      if (value === '') {
        return 'Campo Obrigatório';
      }

      if (
        formRef &&
        formRef.current &&
        formRef.current.getFieldValue('confirmNewPassword') !== value
      ) {
        return 'As senhas não coincidem';
      }

      if (
        formRef &&
        formRef.current &&
        formRef.current.getFieldError('confirmNewPassword') ===
          'As senhas não coincidem'
      ) {
        formRef.current.setFieldError('confirmNewPassword', '');
      }

      return null;
    case 'confirmNewPassword':
      if (value === '') {
        return 'Campo Obrigatório';
      }

      if (
        formRef &&
        formRef.current &&
        formRef.current.getFieldValue('newPassword') !== value
      ) {
        return 'As senhas não coincidem';
      }

      if (
        formRef &&
        formRef.current &&
        formRef.current.getFieldError('newPassword') ===
          'As senhas não coincidem'
      ) {
        formRef.current.setFieldError('newPassword', '');
      }

      return null;
    case 'surname':
      if (value === '' || !value) {
        return emptyText('Sobrenome');
      }

      return null;
    case 'name':
      if (value === '' || !value) {
        return emptyText('Nome');
      }

      return null;

    case 'phone':
      if (value === '' || !value) {
        return emptyText('Telefone');
      }

      if (!validatePhoneNumber(value)) {
        return 'Telfone Inválido';
      }
      return null;

    case 'address':
      if (value === '' || !value) {
        return emptyText('Endereço');
      }
      return null;

    case 'complement':
      if (value === '' || !value) {
        return emptyText('Complemento');
      }
      return null;

    case 'neighborhood':
      if (value === '' || !value) {
        return emptyText('Bairro');
      }
      return null;

    case 'uf':
      if (value === '' || !value) {
        return emptyText('UF');
      }
      return null;

    case 'city':
      if (value === '' || !value) {
        return emptyText('Cidade');
      }
      return null;
    case 'birthdate':
      if (value === '' || !value) {
        return emptyText('Data de nascimento');
      }

      if (!validDate(value)) {
        return 'Data de Aniversário inválida';
      }
      return null;
    case 'gender':
      if (value === '' || !value) {
        return emptyText('Gênero');
      }
      return null;
    case 'cpf':
      if (value === '' || !value) {
        return emptyText('CPF');
      }

      if (!validateCPF(value)) {
        return 'CPF Inválido';
      }
      return null;
    case 'enrollment':
      if (value === '' || !value) {
        return emptyText('Matrícula');
      }

      return null;
    case 'registry':
      if (value === '' || !value) {
        return emptyText('Registro');
      }

      return null;
    case 'title':
      if (value === '' || !value) {
        return emptyText('Título');
      }

      return null;
    case 'category':
      if (value === '' || !value) {
        return emptyText('Categoria');
      }

      return null;
    case 'video':
      if (value === '' || !value) {
        return emptyText('Vídeo');
      }

      return null;
    case 'date':
      if (!value) {
        return emptyText('Data');
      }

      return null;
    case 'time':
      if (!value) {
        return emptyText('Hora');
      }

      return null;
    case 'description':
      if (!value) {
        return emptyText('Hora');
      }

      return null;
    case 'zoomLink':
      if (!value) {
        return emptyText('link da aula');
      }

      return null;
    default:
      return null;
  }
}

export function validateAll(form, notValidate = [], _recursiveKey) {
  let formErrors = {};
  let formIsValid = true;

  Object.keys(form).map((key) => {
    if (typeof form[key] === 'object') {
      const recursiveKey = _recursiveKey ? `${key}.${_recursiveKey}` : key;

      formErrors = {
        ...formErrors,
        ...validateAll(form[key], notValidate, recursiveKey),
      };
    } else {
      const newKey = _recursiveKey ? `${_recursiveKey}.${key}` : key;

      if (!notValidate.find((fieldName) => fieldName === newKey)) {
        const error = validateField(newKey, form[key]);

        if (formIsValid && error) {
          formIsValid = false;
        }

        formErrors[newKey] = error;
      }
    }
  });

  return [formErrors, formIsValid];
}
