import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';

import { TableCard, SimplePopUp } from '../../../../components';
import navigationKeys from '../../../../library/enums/navigationKeys';
import {
  getSubDebateList,
  selectDebateListFetchState,
  subDebateListSelectors,
  deleteClassOfDebate,
} from '../../../../store/debates/subDebateList';
import useFomatDate from '../../../../library/hooks/useFomatDate';

import styles from './debate-detail.module.scss';

export default function SubDebatesTable() {
  const dispatch = useDispatch();
  const history = useHistory();

  const match = useRouteMatch();
  const debateId = useMemo(() => match.params.debateId, [match.params]);

  const [
    classOfDebateMarkedToDelete,
    setClassOfDebateMarkedToDelete,
  ] = useState(null);

  useEffect(() => {
    dispatch(getSubDebateList());
  }, [dispatch]);

  const lsSubDebates = useSelector(subDebateListSelectors.selectAll);
  const fetchState = useSelector(selectDebateListFetchState);

  const goToPage = useCallback(
    (pageIndex) => dispatch(getSubDebateList({ pageIndex })),
    [dispatch],
  );

  const handleDelete = useCallback(() => {
    dispatch(deleteClassOfDebate(classOfDebateMarkedToDelete._id));
    setClassOfDebateMarkedToDelete(null);
  }, [classOfDebateMarkedToDelete, dispatch]);

  const formatDate = useFomatDate();

  return (
    <>
      <TableCard
        style={{ marginTop: 20 }}
        isLoading={fetchState.isLoading}
        page={fetchState.page}
        totalPages={fetchState.totalPages}
        onPageClick={goToPage}
        onNextPageClick={goToPage}
        onPreviusPageClick={goToPage}
        title="Lista de Aulas do Debate"
        showHeaderButton
        headerButtonClick={() =>
          history.push(`${navigationKeys.ADMIN_DEBATES}/${debateId}/class`)
        }
        headerButtonText="Criar Nova Aula do Debate"
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Data</th>
            <th scope="col">Link da Aula</th>
            <th scope="col">Professor</th>
            <th scope="col">Deletar?</th>
          </tr>
        </thead>
        <tbody>
          {lsSubDebates.map((item) => (
            <tr key={item._id}>
              <td>{formatDate(item.date)}</td>
              <td>{item.zoomLink}</td>
              <td>{item.teacher.name}</td>
              <td>
                <button
                  className={styles.removeButton}
                  type="button"
                  onClick={() => setClassOfDebateMarkedToDelete(item)}
                >
                  <FaTrash size={20} color="#8898aa" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </TableCard>
      {!!classOfDebateMarkedToDelete && (
        <SimplePopUp
          isOpen
          onCancelClick={() => setClassOfDebateMarkedToDelete(null)}
          onConfirmClick={handleDelete}
        >
          {' '}
          <span>
            Tem certeza que deseja deletar a aula de data{' '}
            <span style={{ fontWeight: 'bold' }}>
              {formatDate(classOfDebateMarkedToDelete.date)}
            </span>
            ?
          </span>
        </SimplePopUp>
      )}
    </>
  );
}
