import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import navigationKeys from '../library/enums/navigationKeys';

import Home from '../pages/admin/Home';

import AdminsTable from '../pages/admin/admins/AdminsTable';
import AdminsDetails from '../pages/admin/admins/AdminsDetails';

import SubscriptionsTable from '../pages/admin/subscriptions/SubscriptionsTable';
import SubscriptionsDetail from '../pages/admin/subscriptions/SubscriptionsDetail';

import MediatorsTable from '../pages/admin/mediators/MediatorsTable';
import MediatorDetail from '../pages/admin/mediators/MediatorDetail';

import ProfessorsTable from '../pages/admin/professors/ProfessorsTable';
import ProfessorDetails from '../pages/admin/professors/ProfessorDetails';

import StudentsTable from '../pages/admin/students/StudentsTable';
import StudentDetail from '../pages/admin/students/StudentDetail';

import DebatesTable from '../pages/admin/debates/DebatesTable';
import DebateDetail from '../pages/admin/debates/DebateDetail';
import ClassOfDebateDetail from '../pages/admin/debates/ClassOfDebateDetail';

import ClassroomsTable from '../pages/admin/classrooms/ClassroomsTable';
import ClassroomDetail from '../pages/admin/classrooms/ClassroomDetail';

import TagsTable from '../pages/admin/tags/TagsTable';

import NewslettersList from '../pages/admin/newsletters/NewslettersList';

import FormsList from '../pages/admin/forms/FormsList';

import CoursesTable from '../pages/admin/courses/CoursesTable';
import CourseDetail from '../pages/admin/courses/CourseDetail';
import ClassOfCourseDetail from '../pages/admin/courses/ClassOfCourseDetail';

import AdminTopNavBar from '../components/AdminTopNavBar';
// import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from '../components/Sidebar';

import { selectIsUserLogged } from '../store/loggedUser';

const getTitle = (path) => {
  const subRoutePath = path.split('/')[2];

  switch (subRoutePath) {
    case 'home':
      return 'INÍCIO';
    case 'admins':
      return 'ADMINS';
    case 'subscriptions':
      return 'Assinaturas';
    case 'mediators':
      return 'Mediadores';
    case 'professors':
      return 'Professores';
    case 'students':
      return 'Alunos';
    case 'debates':
      return 'Debates';
    case 'classrooms':
      return 'Turmas';
    case 'tags':
      return 'Tags';
    case 'courses':
      return 'Cursos';
    default:
      return '';
  }
};

export default function AdminRoutes({ location }) {
  const isUserLogged = useSelector(selectIsUserLogged);

  if (!isUserLogged) return <Redirect to="/auth" />;

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <AdminTopNavBar title={getTitle(location.pathname)} />
        <Switch>
          <Route path={navigationKeys.ADMIN_HOME} exact component={Home} />
          <Route
            path={navigationKeys.ADMIN_ADMINS}
            exact
            component={AdminsTable}
          />
          <Route
            path={`${navigationKeys.ADMIN_ADMINS}/create`}
            component={AdminsDetails}
          />
          <Route
            path={`${navigationKeys.ADMIN_ADMINS}/:userId`}
            component={AdminsDetails}
          />
          <Route
            path={navigationKeys.ADMIN_SUBSCRIPTIONS}
            exact
            component={SubscriptionsTable}
          />
          <Route
            path={`${navigationKeys.ADMIN_SUBSCRIPTIONS}/create`}
            exact
            component={SubscriptionsDetail}
          />
          <Route
            path={`${navigationKeys.ADMIN_SUBSCRIPTIONS}/:userId`}
            exact
            component={SubscriptionsDetail}
          />
          <Route
            path={navigationKeys.ADMIN_MEDIATORS}
            exact
            component={MediatorsTable}
          />
          <Route
            path={`${navigationKeys.ADMIN_MEDIATORS}/create`}
            component={MediatorDetail}
          />
          <Route
            path={`${navigationKeys.ADMIN_MEDIATORS}/:userId`}
            component={MediatorDetail}
          />
          <Route
            path={navigationKeys.ADMIN_PROFESSORS}
            exact
            component={ProfessorsTable}
          />
          <Route
            path={`${navigationKeys.ADMIN_PROFESSORS}/create`}
            exact
            component={ProfessorDetails}
          />
          <Route
            path={`${navigationKeys.ADMIN_PROFESSORS}/:userId`}
            exact
            component={ProfessorDetails}
          />
          <Route
            path={navigationKeys.ADMIN_STUDENTS}
            exact
            component={StudentsTable}
          />
          <Route
            path={`${navigationKeys.ADMIN_STUDENTS}/create`}
            exact
            component={StudentDetail}
          />
          <Route
            path={`${navigationKeys.ADMIN_STUDENTS}/:userId`}
            exact
            component={StudentDetail}
          />
          <Route
            path={navigationKeys.ADMIN_DEBATES}
            exact
            component={DebatesTable}
          />
          <Route
            path={`${navigationKeys.ADMIN_DEBATES}/create`}
            exact
            component={DebateDetail}
          />
          <Route
            path={`${navigationKeys.ADMIN_DEBATES}/:debateId`}
            exact
            component={DebateDetail}
          />
          <Route
            path={`${navigationKeys.ADMIN_DEBATES}/:debateId/class`}
            exact
            component={ClassOfDebateDetail}
          />
          <Route
            path={navigationKeys.ADMIN_CLASSROOMS}
            exact
            component={ClassroomsTable}
          />
          <Route
            path={`${navigationKeys.ADMIN_CLASSROOMS}/create`}
            exact
            component={ClassroomDetail}
          />
          <Route
            path={`${navigationKeys.ADMIN_CLASSROOMS}/:classroomId`}
            exact
            component={ClassroomDetail}
          />
          <Route
            path={navigationKeys.ADMIN_NEWSLETTERS}
            exact
            component={NewslettersList}
          />
          <Route
            path={navigationKeys.ADMIN_FORMS}
            exact
            component={FormsList}
          />
          <Route
            path={`${navigationKeys.ADMIN_COURSES}`}
            exact
            component={CoursesTable}
          />
          <Route
            path={`${navigationKeys.ADMIN_COURSES}/create`}
            exact
            component={CourseDetail}
          />
          <Route
            path={`${navigationKeys.ADMIN_COURSES}/:courseId`}
            exact
            component={CourseDetail}
          />
          <Route
            path={`${navigationKeys.ADMIN_COURSES}/:courseId/class`}
            exact
            component={ClassOfCourseDetail}
          />
          ClassOfCourseDetail
          <Route path={navigationKeys.ADMIN_TAGS} exact component={TagsTable} />
          <Redirect from="/admin" to={navigationKeys.ADMIN_ADMINS} />
        </Switch>
      </div>
    </>
  );
}
