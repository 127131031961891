import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { getAdminList } from './thunkActions';

const adminListAdapter = createEntityAdapter();

const initialState = {
  fetchState: {
    isLoading: true,
    hasError: false,
    totalPages: -1,
    page: 0,
  },
};

const adminsList = createSlice({
  name: 'root/adminList',
  initialState: adminListAdapter.getInitialState(initialState),
  reducers: {
    resetAdminsList: (state) => {
      adminListAdapter.removeAll(state);
      state.fetchState = initialState.fetchState;
    },
  },
  extraReducers: {
    [getAdminList.pending]: (state) => {
      state.fetchState.isLoading = true;
    },
    [getAdminList.fulfilled]: (state, action) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = false;
      state.fetchState.totalPages = Math.ceil(
        action.payload.total / action.payload.limit,
      );
      state.fetchState.page = action.payload.page;

      adminListAdapter.setAll(state, action.payload.users);
    },
    [getAdminList.rejected]: (state) => {
      state.fetchState.isLoading = false;
      state.fetchState.hasError = true;
    },
  },
});

export { getAdminList };

export const adminListSelectors = adminListAdapter.getSelectors(
  (state) => state.adminsList,
);
export const { resetAdminsList } = adminsList.actions;

export const selectAdminListFetchState = (state) => state.adminsList.fetchState;

export default adminsList.reducer;
