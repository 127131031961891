import { createAsyncThunk } from '@reduxjs/toolkit';
import { showToast } from '../../toast';
import { showLoader, hideLoader } from '../../loader';
import SubscriptionService from '../../../services/SubscriptionService';
import { handleErrorMessage } from '../../../library/utils/errorHandler';

export const getSubscriptionDetails = createAsyncThunk(
  'subscriptionsDetails/getSubscriptionDetails',
  async (subscriptionsId, { dispatch }) => {
    try {
      const response = await SubscriptionService.getSubscriptionById(
        subscriptionsId,
      );

      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const updateSubscriptionById = createAsyncThunk(
  'subscriptionDetails/updateSubscriptionById',
  async ({ id, ...rest }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await SubscriptionService.updateSubscriptionById(
        id,
        rest,
      );

      dispatch(hideLoader());

      dispatch(
        showToast({
          type: 'success',
          text: 'Mediador Atualizado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);

export const createSubscription = createAsyncThunk(
  'subscriptionDetails/createSubscription',
  async (subscription, { dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await SubscriptionService.createSubscription(
        subscription,
      );

      dispatch(hideLoader());
      dispatch(
        showToast({
          type: 'success',
          text: 'Mediador Criado com sucesso!',
        }),
      );
      return response.data;
    } catch (error) {
      handleErrorMessage(error, dispatch);
      throw error.data;
    }
  },
);
